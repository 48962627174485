import React, { createContext, useState } from 'react';

const DemoModalContext = createContext();

function DemoModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(open => !open);
  return (
    <DemoModalContext.Provider value={{ isOpen, toggleOpen }}>
      {children}
    </DemoModalContext.Provider>
  );
}

export { DemoModalProvider, DemoModalContext };
