import { Divider, Form, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import Input from '../widgets/Input';
import newId from 'uuid4';

const typeFields = [
  'labelStyleRollOfLabels',
  'labelStyleSheetOfLabels',
  'labelStyleNeither',
];

const optionChangeHandler = (labelField, setFieldValue) => () => {
  typeFields.forEach(field => {
    setFieldValue(field, field === labelField);
  });
};

const checkChange = setFieldValue => (e, { name, checked }) => {
  setFieldValue(name, checked);
};

export const PrinterAndLabelFields = ({
  id = newId(),
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => (
  <Fragment>
    <Grid columns={1}>
      <Grid.Row>
        <Grid.Column>
          <Form.Group widths="equal">
            <Input
              fluid
              label="Printer Manufacturer"
              name="manufacturer"
              value={values.manufacturer}
              placeholder="Manufacturer..."
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.manufacturer && touched.manufacturer && errors.manufacturer}
            />
            <Input
              fluid
              label="Printer Model #"
              name="modelNumber"
              value={values.modelNumber}
              placeholder="Model #..."
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.modelNumber && touched.modelNumber && errors.modelNumber}
            />
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Group widths="equal">
            <Input
              fluid
              label="Label Height"
              name="labelHeight"
              value={values.labelHeight}
              placeholder="Label Height..."
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.labelHeight && touched.labelHeight && errors.labelHeight}
            />
            <Input
              fluid
              label="Label Width (in)"
              name="labelWidth"
              value={values.labelWidth}
              placeholder="Label Width..."
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.labelWidth && touched.labelWidth && errors.labelWidth}
            />
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <span>Which of these best describes your labels for this printer?</span>
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Form.Group>
        <Grid.Row>
          <Grid.Column>
            <Form.Radio
              label="Roll of Labels, one label at a time"
              type="radio"
              name={`labelStyle${id}`}
              id={`labelStyleRollOfLabels${id}`}
              checked={values.labelStyleRollOfLabels}
              onClick={optionChangeHandler('labelStyleRollOfLabels', setFieldValue)}
            />

            <Form.Radio
              label="Sheet of Labels, multiple labels per page"
              name={`labelStyle${id}`}
              id={`labelStyleSheetOfLabels${id}`}
              type="radio"
              checked={values.labelStyleSheetOfLabels}
              onClick={optionChangeHandler('labelStyleSheetOfLabels', setFieldValue)}
            />

            <Form.Radio
              label="Neither of those, something completely different"
              type="radio"
              name={`labelStyle${id}`}
              id={`labelStyleNeither${id}`}
              checked={values.labelStyleNeither}
              onClick={optionChangeHandler('labelStyleNeither', setFieldValue)}
            />

            <Form.Checkbox
              label="We have not selected a printer yet"
              name="noPrinterSelected"
              checked={values.noPrinterSelected}
              onChange={checkChange(setFieldValue)}
            />
          </Grid.Column>
        </Grid.Row>
      </Form.Group>
    </Grid>
  </Fragment>
);
