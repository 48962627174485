import { navigate } from 'gatsby';

const MAX_RESUME_ATTEMPTS = 5;
let resumeAttempts = 0;

export const useResume = () => {
  const noResumeAttemptsLeft = () => resumeAttempts >= MAX_RESUME_ATTEMPTS;

  const run = () => {
    if (noResumeAttemptsLeft()) return;

    resumeAttempts = resumeAttempts + 1;

    navigate('/Wizard/Resume');
  };

  return {
    noResumeAttemptsLeft,
    run,
  };
};
