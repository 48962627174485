import {
  DRIVERS_LICENSE_FIELDS,
  FIELD_NAMES,
  MAXIMUM_BIRTH_DATE,
  MAXIMUM_HIRE_DATE,
  PERMISSION_FIELDS,
  validate,
} from '../../utilities/employees';
import { Divider, Form, Grid, Icon, Message } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import Button from '../widgets/Button';
import Checkbox from '../widgets/Checkbox';
import DateInput from '../widgets/DateInput';
import { Formik } from 'formik';
import Input from '../widgets/Input';
import LicenseAssociations from '../widgets/LicenseAssociations';
import StateSelect from '../widgets/StateSelect';
import { getLicenses } from '../../utilities/licenses';
import { getVehicles } from '../../utilities/vehicles';

const changeAssociatedVehicles = (value, setFieldValue) =>
  setFieldValue(FIELD_NAMES.associatedVehicles, value);

const vehicleIsAssociated = (vehicle, { associatedVehicles }) =>
  associatedVehicles.indexOf(vehicle.id) !== -1;

const addVehicle = (vehicle, setFieldValue, associatedVehicles) => {
  if (vehicleIsAssociated(vehicle, { associatedVehicles })) return;
  changeAssociatedVehicles(associatedVehicles.concat(vehicle.id), setFieldValue);
};

const removeVehicle = (vehicle, setFieldValue, associatedVehicles) => {
  changeAssociatedVehicles(
    associatedVehicles.filter(val => val !== vehicle.id),
    setFieldValue
  );
};

const createVehicleAssociationHandler = (
  vehicle,
  setFieldValue,
  { associatedVehicles }
) => (event, { checked }) => {
  const handler = checked ? addVehicle : removeVehicle;
  handler(vehicle, setFieldValue, associatedVehicles);
};

const removeAllPermissionsForAccessToGrowFlowAccount = setFieldValue => {
  setFieldValue(FIELD_NAMES.shouldAddAsUserOnYourGrowFlowAccount, false);

  PERMISSION_FIELDS.forEach(field => setFieldValue(field, false));
};

const createDateChangeHandler = (fieldName, setFieldValue) => (e, { value }) => {
  setFieldValue(fieldName, value === null ? '' : value);
};

const isADriverOnDeliveries = ({ vehicles, associatedVehicles, setFieldValue }) => {
  if (vehicles.length === 1 && associatedVehicles.length === 0) {
    addVehicle(vehicles[0], setFieldValue, associatedVehicles);
  }

  setFieldValue(FIELD_NAMES.isDriverOnDeliveries, true);
};

const notADriverOnDeliveries = ({ associatedVehicles, setFieldValue }) => {
  associatedVehicles.forEach(id =>
    removeVehicle({ id }, setFieldValue, associatedVehicles)
  );

  DRIVERS_LICENSE_FIELDS.forEach(field => setFieldValue(field, ''));
  setFieldValue(FIELD_NAMES.isDriverOnDeliveries, false);
};

const identitySection = ({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  values,
}) => {
  const dateOfBirthChangeHandler = createDateChangeHandler('dateOfBirth', setFieldValue);
  const dateOfHireChangeHandler = createDateChangeHandler('dateOfHire', setFieldValue);

  return (
    <Grid.Column width={11}>
      <Form.Group widths="equal">
        <Input
          fluid
          label="First Name"
          name="firstName"
          required
          value={values.firstName}
          placeholder="First Name"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.firstName && touched.firstName && errors.firstName}
        />

        <Input
          fluid
          label="Last Name"
          name="lastName"
          required
          value={values.lastName}
          placeholder="Last Name"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.lastName && touched.lastName && errors.lastName}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Input
          label="Email"
          name="email"
          required
          value={values.email}
          placeholder="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email && errors.email}
        />

        <Input
          fluid
          label="Role/Job Title"
          name="role"
          required
          value={values.role}
          placeholder="Role"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.role && touched.role && errors.role}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Input
          fluid
          label="Phone Number"
          name="phoneNumber"
          type="text"
          maxLength="14"
          required
          value={values.phoneNumber}
          placeholder="Phone Number"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
        />

        <DateInput
          fluid
          clearable
          required
          label="Date of Birth"
          name="dateOfBirth"
          maxDate={MAXIMUM_BIRTH_DATE}
          startMode="year"
          value={values.dateOfBirth}
          placeholder="Date Of Birth"
          onChange={dateOfBirthChangeHandler}
          onBlur={handleBlur}
          error={errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <DateInput
          clearable
          fluid
          label="Date of Hire"
          maxDate={MAXIMUM_HIRE_DATE}
          name="dateOfHire"
          onBlur={handleBlur}
          onChange={dateOfHireChangeHandler}
          placeholder="Date of Hire"
          required
          value={values.dateOfHire}
          error={errors.dateOfHire && touched.dateOfHire && errors.dateOfHire}
        />

        <Input
          fluid
          label="Employee/Owner Id (Optional)"
          name="employeeId"
          value={values.employeeId}
          placeholder="Employee/Owner Id"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.employeeId && touched.employeeId && errors.employeeId}
        />
      </Form.Group>
    </Grid.Column>
  );
};

const driverOnDeliveriesSection = ({
  setFieldValue,
  vehicles,
  values: { isDriverOnDeliveries, associatedVehicles },
}) => (
  <Form.Group inline>
    <label>Is this employee/owner a driver on deliveries? </label>

    <Form.Radio
      label="Yes"
      name="isDriverOnDeliveries"
      id="driverOnDeliveriesYes"
      value={true}
      type="radio"
      checked={isDriverOnDeliveries}
      onChange={() => {
        isADriverOnDeliveries({
          vehicles,
          associatedVehicles,
          setFieldValue,
        });
      }}
    />

    <Form.Radio
      label="No"
      name="isDriverOnDeliveries"
      value={false}
      id="driverOnDeliveriesNo"
      type="radio"
      checked={!isDriverOnDeliveries}
      onChange={() =>
        notADriverOnDeliveries({
          associatedVehicles,
          setFieldValue,
        })
      }
    />
  </Form.Group>
);

const driversLicenseSection = ({
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) =>
  values.isDriverOnDeliveries ? (
    <Fragment>
      <p>Drivers License Information</p>

      <Divider />
      <Form.Group widths="equal">
        <StateSelect
          fluid
          label="State"
          required
          name="state"
          id="state"
          value={values.state}
          placeholder="Select A State"
          onChange={setFieldValue}
          error={errors.state && touched.state && errors.state}
        />

        <Input
          fluid
          label="Drivers License #"
          id="driverLicenseNumber"
          name="driverLicenseNumber"
          required
          value={values.driverLicenseNumber}
          placeholder="Driver License #"
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.driverLicenseNumber &&
            touched.driverLicenseNumber &&
            errors.driverLicenseNumber
          }
        />
      </Form.Group>
    </Fragment>
  ) : null;

const associatedVehiclesSection = ({ setFieldValue, values, vehicles, errors }) =>
  values.isDriverOnDeliveries ? (
    <Fragment>
      <p>Associated Vehicles</p>
      <Divider />
      <Form.Group>
        {values &&
          vehicles.map(vehicle => (
            <Checkbox
              label={vehicle.nickname}
              name={vehicle.nickname}
              key={vehicle.id}
              checked={vehicleIsAssociated(vehicle, values)}
              onChange={createVehicleAssociationHandler(vehicle, setFieldValue, values)}
            />
          ))}
      </Form.Group>
      {errors.isDriverOnDeliveries && (
        <Message negative icon>
          <Icon name="exclamation" />
          <Message.Content>
            <Message.Header>Vehicle Assignment Is Required</Message.Header>
            <p>{errors.isDriverOnDeliveries}</p>
          </Message.Content>
        </Message>
      )}
    </Fragment>
  ) : null;

const addAsUserOnYourGrowFlowAccountSection = ({
  handleChange,
  values: { shouldAddAsUserOnYourGrowFlowAccount },
  setFieldValue,
}) => (
  <Form.Group inline>
    <label>Add as User on your GrowFlow Account? </label>

    <Form.Radio
      label="Yes"
      name="shouldAddAsUserOnYourGrowFlowAccount"
      id="assignOnYourGrowFlowAccount"
      value="yes"
      type="radio"
      checked={shouldAddAsUserOnYourGrowFlowAccount}
      onChange={handleChange}
    />

    <Form.Radio
      label="No"
      name="shouldAddAsUserOnYourGrowFlowAccount"
      id="dontAssignOnYourGrowFlowAccount"
      value="no"
      type="radio"
      checked={!shouldAddAsUserOnYourGrowFlowAccount}
      onChange={() => removeAllPermissionsForAccessToGrowFlowAccount(setFieldValue)}
    />
  </Form.Group>
);

const userPermissionsSection = ({
  values: {
    shouldAddAsUserOnYourGrowFlowAccount,
    hasGrowAccess,
    hasSalesAccess,
    isAdministrator,
  },
  handleChange,
  handleBlur,
  errors,
}) =>
  shouldAddAsUserOnYourGrowFlowAccount ? (
    <Fragment>
      <p>User Permissions</p>
      <Divider />
      <Form.Group widths="equal">
        <Checkbox
          id="isAdministrator"
          checked={isAdministrator}
          label="Administrator"
          name="isAdministrator"
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Checkbox
          checked={hasGrowAccess}
          id="hasGrowAccess"
          label="Grow Access"
          name="hasGrowAccess"
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Checkbox
          checked={hasSalesAccess}
          id="hasSalesAccess"
          label="Sales Access"
          name="hasSalesAccess"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      {errors.shouldAddAsUserOnYourGrowFlowAccount && (
        <Message negative icon>
          <Icon name="exclamation" />
          <Message.Content>
            <Message.Header>User Permissions Are Required</Message.Header>
            <p>{errors.shouldAddAsUserOnYourGrowFlowAccount}</p>
          </Message.Content>
        </Message>
      )}
    </Fragment>
  ) : null;

const actionsSection = ({
  working,
  onCancel,
  canCancel,
  isEditingEmployee,
  nextInternalStep,
}) => (
  <Form.Group widths="equal">
    <Button
      disabled={working}
      loading={working}
      size="large"
      type="submit"
      className="button-container add-employee"
    >
      + Submit Employee/Owner
    </Button>

    {canCancel && (
      <Button
        size="large"
        onClick={() => onCancel(nextInternalStep)}
        className="button-container cancel"
      >
        {isEditingEmployee ? 'Cancel' : "I've Added all Employees/Owners"}
      </Button>
    )}
  </Form.Group>
);

const licensesSection = ({
  errors,
  handleBlur,
  handleChange,
  licenses,
  setFieldValue,
  values: { associatedLicenses },
}) => (
  <Grid.Column width={5}>
    <p>Associated Licenses</p>

    <Divider />

    <LicenseAssociations
      errors={errors}
      licenses={licenses}
      associatedLicenses={associatedLicenses}
      licenseAssignmentRequired={true}
      associationFieldName="associatedLicenses"
      handleBlur={handleBlur}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
    />
  </Grid.Column>
);

const EmployeeInformationForm = ({
  initialValues,
  working,
  onSubmit,
  setOpen,
  isEditingEmployee,
  introInformation,
  nextInternalStep,
  nextStep,
  canCancel,
  onCancel,
  skippableSectionBuilder,
  skippingEmployees,
  vehiclesAreSetup,
}) => {
  const { licenses = [] } = getLicenses();
  const vehicles = Object.values(getVehicles());

  return (
    <Fragment>
      {skippableSectionBuilder(
        <Fragment>
          <h3>Employee/Owner Details for {introInformation.companyName}</h3>
          <Formik
            initialValues={initialValues}
            validate={values =>
              validate({
                values,
                vehicles,
              })
            }
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm();
              setOpen ? setOpen(false) : null;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              /* and other goodies */
            }) => {
              const sectionDetails = {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                canCancel,
                onCancel,
                working,
                nextInternalStep,
                isEditingEmployee,
              };

              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <Grid columns={2}>
                    {identitySection(sectionDetails)}

                    {licensesSection({
                      licenses,
                      ...sectionDetails,
                    })}
                  </Grid>

                  {vehiclesAreSetup &&
                    driverOnDeliveriesSection({
                      vehicles,
                      ...sectionDetails,
                    })}

                  {vehiclesAreSetup &&
                    driversLicenseSection({
                      ...sectionDetails,
                    })}

                  {vehiclesAreSetup &&
                    associatedVehiclesSection({
                      vehicles,
                      ...sectionDetails,
                    })}

                  {addAsUserOnYourGrowFlowAccountSection({
                    ...sectionDetails,
                  })}

                  {userPermissionsSection({
                    ...sectionDetails,
                  })}

                  {actionsSection(sectionDetails)}
                </Form>
              );
            }}
          </Formik>
        </Fragment>
      )}
      <br />
      {skippingEmployees && (
        <Grid>
          <Grid.Column width="5">
            <Button onClick={nextStep}>Skip</Button>
          </Grid.Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default EmployeeInformationForm;
