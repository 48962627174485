import { Button, Modal } from 'semantic-ui-react';
import { newPlant, validatePlant } from '../../utilities/plants';
import { PlantFields } from './PlantFields';
import React from 'react';
import { form } from '../../utilities/forms';

export const PlantForm = ({
  cancelText,
  fieldValues,
  onCancel,
  onSubmit,
  showCancel = true,
  stages,
  strains,
  submitText,
  validator,
}) =>
  form({
    cancelText,
    enableReinitialize: false,
    initialValues: fieldValues,
    showCancel,
    onCancel,
    onSubmit,
    renderFields: fieldProps => (
      <PlantFields {...fieldProps} strains={strains} stages={stages} />
    ),
    submitText,
    validator,
  });

export const ModalEditPlantForm = ({
  deleteHandler,
  onClose,
  plant,
  room,
  saveHandler,
  stages,
  strains,
}) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>Edit Plant "{name}"</h2>
      <h3>On License # {room.license} </h3>
    </Modal.Header>
    <Modal.Content>
      <PlantForm
        license={room.license}
        validator={validatePlant}
        submitText="Save"
        cancelText="Cancel"
        onSubmit={saveHandler}
        onCancel={onClose}
        fieldValues={plant}
        strains={strains}
        stages={stages}
      />
      <Button onClick={deleteHandler}>Delete</Button>
    </Modal.Content>
  </Modal>
);

export const ModalAddPlantForm = ({ onClose, room, saveHandler, stages, strains }) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>Add Plant</h2>
      <h3>On License # {room.license} </h3>
    </Modal.Header>
    <Modal.Content>
      <PlantForm
        cancelText="Cancel"
        fieldValues={{ ...newPlant(room.id) }}
        license={room.license}
        onCancel={onClose}
        onSubmit={saveHandler}
        stages={stages}
        strains={strains}
        submitText="Add"
        validator={validatePlant}
      />
    </Modal.Content>
  </Modal>
);
