import './LicenseForm.scss';

import { Divider, Form, Grid, Icon, Input, Label, Message } from 'semantic-ui-react';
import React, { useRef, useState } from 'react';
import { isValidPassword, passwordConstraintDescription } from '../../utilities/users';
import {
  leadSourceOptions,
  previousTraceabilityProviderOptions,
} from '../../data/extraDropDownOptions';

import { Formik } from 'formik';
import { hasNoItems } from '../../utilities/matchers';

const handleDropDownChange = setFieldValue => (e, { name, value }) => {
  setFieldValue(name, value);
};

const validate = values => {
  const errors = {};

  if (values.jobTitle === '') errors.jobTitle = 'Required';
  if (!isValidPassword(values.password)) errors.password = passwordConstraintDescription;
  if (values.confirmPassword === '') errors.confirmPassword = 'Required';
  if (values.previousProvider === '') errors.previousProvider = 'Required';
  if (values.leadSource === '') errors.leadSource = 'Required';
  if (values.agreeTerms !== true) {
    errors.agreeTerms =
      'You must accept the GrowFlow EULA & Privacy Policy to create an account';
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Password and confirm password must match';
  }
  if (!(values.wantsToUseGrowFlowProduct || values.wantsToUseRetailProduct)) {
    errors.noGrowFlowProductsSelectedForUser =
      'Please choose which growflow product(s) you want to use';
  }

  return errors;
};
let tempLicense = '';

const checkChange = setFieldValue => (e, { name, checked }) => {
  setFieldValue(name, checked);
};

const LicenseForm = ({
  submitAttempts,
  deleteLicense,
  addLicense,
  isValidLicenseNumber,
  isValidatingLicense,
  setIsValidLicenseNumber,
  initialValues,
  licenses,
  working,
  onSubmit,
  error,
}) => {
  const [providers, setProviders] = useState(
    Object.values(previousTraceabilityProviderOptions)
  );

  const handleAddProvider = (e, { value }) => {
    providers.push({ id: providers.length, value, text: value });
    setProviders(providers);
  };

  const fieldValues = {
    jobTitle: (initialValues && initialValues.jobTitle) || '',
    password: (initialValues && initialValues.password) || '',
    confirmPassword: (initialValues && initialValues.confirmPassword) || '',
    licenses: (initialValues && initialValues.licenses) || '',
    previousProvider: (initialValues && initialValues.previousProvider) || '',
    leadSource: (initialValues && initialValues.leadSource) || '',
    wantsToUseGrowFlowProduct:
      (initialValues && initialValues.wantsToUseGrowFlowProduct) || false,
    wantsToUseRetailProduct:
      (initialValues && initialValues.wantsToUseRetailProduct) || false,
  };

  const updateLicense = licenseVal => {
    tempLicense = licenseVal;
  };

  const licenseInputRef = useRef(null);

  const clearLicenseInput = () => {
    const input = licenseInputRef.current.inputRef.current;
    tempLicense = '';
    input.value = '';
    input.focus();
  };

  const handleAddLicense = async e => {
    setIsValidLicenseNumber(true);

    e.preventDefault();

    if (tempLicense.length <= 2) return;

    await addLicense(tempLicense);

    clearLicenseInput();
  };

  const validatingLicenseTemplate = () =>
    isValidatingLicense ? (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Validating License</Message.Header>
          Please wait while we validate your license number.
        </Message.Content>
      </Message>
    ) : null;

  return (
    <>
      {error && <Message error header={error} />}

      <Formik
        initialValues={fieldValues}
        validate={validate}
        onSubmit={values => {
          values.licenses = licenses;
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Input
              fluid
              label="Job Title"
              required
              name="jobTitle"
              value={values.jobTitle}
              placeholder="Manager"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.jobTitle && touched.jobTitle && errors.jobTitle}
            />
            <Form.Input
              fluid
              label="Password"
              type="password"
              required
              name="password"
              value={values.password}
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password && errors.password}
            />
            <Form.Input
              fluid
              label="Confirm Password"
              type="password"
              required
              name="confirmPassword"
              value={values.confirmPassword}
              placeholder="Confirm Password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
            />
            {validatingLicenseTemplate()}
            {!isValidatingLicense && (
              <Form.Field required>
                <label>License Number</label>
                <Input
                  fluid
                  ref={licenseInputRef}
                  required
                  action={{
                    icon: 'plus',
                    color: 'green',
                    onClick: async e => {
                      await handleAddLicense(e);
                    },
                  }}
                  name="licenses"
                  defaultValue={tempLicense}
                  placeholder="JM123123123"
                  onChange={e => {
                    updateLicense(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleAddLicense}
                />
              </Form.Field>
            )}
            {hasNoItems(licenses) && submitAttempts > 0 && (
              <Message icon negative>
                <Icon name="exclamation" />
                <Message.Content>
                  <Message.Header>
                    At least one valid license number is required
                  </Message.Header>
                </Message.Content>
              </Message>
            )}
            {!isValidLicenseNumber && (
              <Message icon negative>
                <Icon name="exclamation" />
                <Message.Content>
                  <Message.Header>Unable to verify your License Number</Message.Header>
                  <p>
                    We were unable to validate your license number. Try entering it again
                    incase there was a typo.
                  </p>
                  <p>
                    If you are positive that you are entering your license number
                    correctly, please email{' '}
                    <a href="mailto:activation@getgrowflow.com">
                      activation@getgrowflow.com
                    </a>{' '}
                    for assistance.
                  </p>
                  <p>
                    {' '}
                    We will make sure your license is valid with your state legislature
                    and get your account setup immediately!
                  </p>
                </Message.Content>
              </Message>
            )}
            <div style={{ minHeight: '60px', marginBottom: '10px', color: '#8D8D8F' }}>
              <Icon name="info circle" color="olive" />
              Add all of the License Numbers you own
              <br />
              <br />
              <Grid columns="2" stackable>
                {licenses.map(license => (
                  <Grid.Column width="5" key={license}>
                    <Label color="olive" style={{ width: '100%' }} size="small">
                      <Icon name="delete" onClick={() => deleteLicense(license)} />
                      <span style={{ paddingLeft: '6px' }}> {license}</span>
                    </Label>
                  </Grid.Column>
                ))}
              </Grid>
            </div>
            <p>Which Growflow products would you like to use?</p>
            <Divider />

            <Form.Checkbox
              name="wantsToUseGrowFlowProduct"
              label="Grow Product"
              checked={values.wantsToUseGrowFlowProduct}
              onChange={checkChange(setFieldValue)}
            />
            <div className="product-hint-text">
              Cultivator, Processor, Manufacturer, Distributer
            </div>

            <Form.Checkbox
              name="wantsToUseRetailProduct"
              label="Retail Product"
              checked={values.wantsToUseRetailProduct}
              onChange={checkChange(setFieldValue)}
            />
            <div className="product-hint-text">Dispensary, Retail</div>

            {errors.noGrowFlowProductsSelectedForUser && (
              <Message header={errors.noGrowFlowProductsSelectedForUser} />
            )}

            <Form.Dropdown
              closeOnChange
              multiple
              clearable
              required
              allowAdditions
              search
              selection
              fluid
              className="Input"
              error={
                errors.previousProvider &&
                touched.previousProvider &&
                errors.previousProvider
              }
              id="previousProvider"
              name="previousProvider"
              label="Previous Traceability Provider"
              onBlur={handleBlur}
              onChange={handleDropDownChange(setFieldValue)}
              onAddItem={handleAddProvider}
              options={providers}
              placeholder="Choose an item or type a name here"
              value={values.previousProvider}
            />

            <Form.Dropdown
              clearable
              allowEmpty
              required
              className="Input"
              error={errors.leadSource && touched.leadSource && errors.leadSource}
              search
              selection
              fluid
              id="leadSource"
              name="leadSource"
              label="How did you hear about GrowFlow?"
              onBlur={handleBlur}
              onChange={handleDropDownChange(setFieldValue)}
              options={Object.values(leadSourceOptions)}
              placeholder="Choose an item..."
              value={values.leadSource}
            />
            <Form.Checkbox
              name="agreeTerms"
              checked={values.agreeTerms}
              onChange={checkChange(setFieldValue)}
              label={{
                children: (
                  <p>
                    By checking this box and creating an account you agree to our{' '}
                    <a
                      href="https://getgrowflow.com/eula/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EULA
                    </a>{' '}
                    as well as our{' '}
                    <a
                      href="https://getgrowflow.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                    .
                  </p>
                ),
              }}
            />
            {errors.agreeTerms && (
              <Message negative>
                <Message.Header>{errors.agreeTerms}</Message.Header>
              </Message>
            )}
            <Form.Button primary type="submit" disabled={working} loading={working}>
              Create My Account
            </Form.Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LicenseForm;
