import { Container, Divider } from 'semantic-ui-react';
import React from 'react';
import RoomItem from './RoomItem';

const RoomList = ({ title, rooms, onEdit }) => (
  <Container>
    {title}
    <Divider />
    {rooms.map(room => (
      <RoomItem key={room.id} id={room.id} title={room.name} onEdit={onEdit} />
    ))}
  </Container>
);

export default RoomList;
