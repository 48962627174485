import './PlantItem.scss';
import { Button, Icon } from 'semantic-ui-react';
import React from 'react';

const PlantItem = ({ id, title, onEdit }) => (
  <div key={title} className="PlantItem">
    <Button onClick={() => onEdit(id)}>
      <Icon name="edit" />
      <span>{title}</span>
    </Button>
  </div>
);

export default PlantItem;
