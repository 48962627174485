import { Grid } from "semantic-ui-react";
import React from "react";

export const Columns = ({ children, left, center, right }) => {
  const count = (children || []).length + (left ? 1 : 0) + (center ? 1 : 0) + (right ? 1 : 0);

  return (
    <Grid columns={count} stackable>
      {left && <Column count={count}>{left}</Column>}
      {center && <Column count={count}>{center}</Column>}
      {right && <Column count={count}>{right}</Column>}
      {children}
    </Grid>
  );
};

export const Column = ({
  children,
  width, // if specified, uses this width out of 24 units.
  count // if specified, the column's width is 24 / count (count is the total number of columns).
}) => (
  <Grid.Column width={width ? width : (count ? 16 / count : 16)}>
    {children}
  </Grid.Column>
);
