import { Form, Message } from 'semantic-ui-react';

import { Formik } from 'formik';
import React from 'react';

// validation rules
const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.gender) {
    errors.gender = 'Required';
  }
  return errors;
};

// all options need an id
const options = [
  { text: 'Male', value: 'male', id: 'male' },
  { text: 'Female', value: 'female', id: 'female' },
  { text: 'Other', value: 'other', id: 'other' },
];

// select dropdowns need a special change handler to work with semantic ui
const handleDropdownChange = (e, { name, value }, setFieldValue) => {
  setFieldValue(name, value);
};

const DemoForm = ({ initialValues, working, onSubmit, error }) => {
  // forms need to initialize with values, even if they are blank, so that they are always controled
  const fieldValues = {
    firstName: (initialValues && initialValues.firstName) || '',
    lastName: (initialValues && initialValues.lastName) || '',
    gender: (initialValues && initialValues.gender) || '',
    size: (initialValues && initialValues.size) || '',
    textarea: (initialValues && initialValues.textarea) || '',
    agree: (initialValues && initialValues.agree) || '',
  };

  return (
    <>
      <h1>Demo Form Component</h1>
      {error && <Message error header={error} />}
      <Formik
        initialValues={fieldValues}
        validate={values => validate(values)}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="First name"
                required
                name="firstName"
                value={values.firstName}
                placeholder="First name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName && touched.firstName && errors.firstName}
              />
              <Form.Input
                fluid
                label="Last name"
                name="lastName"
                required
                value={values.lastName}
                placeholder="Last name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName && touched.lastName && errors.lastName}
              />
              <Form.Select
                fluid
                label="Gender"
                required
                name="gender"
                id="gender"
                options={options}
                value={values.gender}
                placeholder="Gender"
                onChange={(e, selectData) =>
                  handleDropdownChange(e, selectData, setFieldValue)
                }
                onBlur={handleBlur}
                error={errors.gender && touched.gender && errors.gender}
              />
            </Form.Group>
            <Form.Group inline>
              <label>Size</label>
              <Form.Radio
                label="Small"
                name="size"
                id="sm"
                value="sm"
                type="radio"
                checked={values.size === 'sm'}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Radio
                label="Medium"
                name="size"
                value="md"
                id="md"
                type="radio"
                checked={values.size === 'md'}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Radio
                label="Large"
                value="lg"
                id="lg"
                type="radio"
                name="size"
                checked={values.size === 'lg'}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.TextArea
              name="textarea"
              label="Text Area"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Tell us more about you..."
              value={values.textarea}
            />
            <Form.Checkbox
              label="I agree to the Terms and Conditions"
              name="agree"
              type="checkbox"
              checked={values.agree}
              id="agree"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Button primary type="submit" disabled={working} loading={working}>
              Submit
            </Form.Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DemoForm;
