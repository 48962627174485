import { Icon, Message } from 'semantic-ui-react';
import React, { Fragment, useEffect } from 'react';
import Checkbox from './Checkbox';

const LicenseAssociations = ({
  licenseAssignmentRequired,
  associationFieldName,
  errors,
  licenses,
  associatedLicenses,
  setFieldValue,
}) => {
  const allLicensesAreAssociated =
    associatedLicenses.length === licenses.length && licenses.length > 0;

  const changeAssociatedLicenses = value => setFieldValue(associationFieldName, value);

  const licenseIsAssociated = (license, { associatedLicenses }) =>
    associatedLicenses.indexOf(license) !== -1;

  const addLicense = license => {
    changeAssociatedLicenses(associatedLicenses.concat(license));
  };

  const removeLicense = license =>
    changeAssociatedLicenses(associatedLicenses.filter(val => val !== license));

  const toggleAllLicenses = (event, { checked }) => {
    const newValue = checked ? licenses.slice() : [];
    setFieldValue(associationFieldName, newValue);
  };

  const licensesHaveBeenRegistered = licenses.length > 0;

  const createLicenseAssociationHandler = license => (event, data) => {
    const handler = data.checked ? addLicense : removeLicense;
    handler(license);
  };

  const autoAssignSingleLicense = () => {
    if (licenses.length === 1 && associatedLicenses.length === 0) {
      addLicense(licenses[0]);
    }
  };

  useEffect(autoAssignSingleLicense, [associatedLicenses.length]);

  return (
    <Fragment>
      {licensesHaveBeenRegistered && (
        <Checkbox
          label="Select All"
          checked={allLicensesAreAssociated}
          onChange={toggleAllLicenses}
        />
      )}

      {associatedLicenses &&
        licenses.map(license => (
          <Checkbox
            label={license}
            name={license}
            key={license}
            checked={licenseIsAssociated(license, { associatedLicenses })}
            onChange={createLicenseAssociationHandler(license)}
          />
        ))}
      {errors.shouldHaveAtLeastOneLicenseAssigned && licenseAssignmentRequired && (
        <Message icon negative>
          <Icon name="exclamation" />
          <Message.Content>
            <Message.Header>License Assignment</Message.Header>
            <p>{errors.shouldHaveAtLeastOneLicenseAssigned}</p>
          </Message.Content>
        </Message>
      )}
    </Fragment>
  );
};

export default LicenseAssociations;
