import './ProgressItems.scss';
import { Link } from 'gatsby';
import { List } from 'semantic-ui-react';
import React from 'react';
import classes from 'classnames';

const ProgressItems = ({ menu, currentStepNum }) => (
  <List size="big" className="ProgressItems">
    {menu.map(({ path, prettyName, isAvailable, completed, skipped }, index) => (
      <List.Item key={path}>
        <List.Header>
          <List.Icon
            className="circle-icon"
            name={completed ? 'circle' : 'circle outline'}
          />
          <Link
            className={classes({
              underlineLink: index === currentStepNum,
              'disabled-link': !isAvailable,
              'italicized-link': skipped,
            })}
            to={path}
          >
            {index + 1}. {prettyName}
          </Link>
        </List.Header>
      </List.Item>
    ))}
  </List>
);

export default ProgressItems;
