import { Button, Divider, Grid, List } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import { WistiaVideo, layoutTemplates } from '../widgets/WistiaVideo';
import DocumentIcon from './DocumentIcon';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { WizardLayout } from 'components';
import classes from 'classnames';
import { stepIsAvailableInCurrentWorkFlow } from '../../utilities/steps';

const noOp = () => {};

const PageIntroduction = ({ onSubmit, step }) => {
  const stepIsAvailable = stepIsAvailableInCurrentWorkFlow(step);

  const {
    intro: {
      header,
      bodyText,
      bodyText2,
      requirements,
      buttonText,
      videoID,
      needHelp: { link: needHelpLink, text: needHelpText },
    },
  } = step;

  const buttonAvailabilityText = stepIsAvailable
    ? buttonText
    : 'Prior steps require completion';

  const onSubmitHandler = stepIsAvailable ? onSubmit : noOp;

  const buttonClasses = classes({
    positive: stepIsAvailable,
    negative: !stepIsAvailable,
    'add-button': true,
  });

  return (
    <WizardLayout text>
      <div className="page-introduction">
        <Grid columns={2} stackable className="header-container">
          <Grid.Column width={2}>
            <DocumentIcon />
          </Grid.Column>
          <Grid.Column width={14}>
            <h1>{header}</h1>
            <p>This usually takes about 5 minutes</p>
          </Grid.Column>
        </Grid>
        <Divider />
        <Grid columns={2} stackable>
          <Grid.Column width={8} className="left-column">
            <h3>Why do we need to ask for this?</h3>
            <p className="body-text">{bodyText}</p>
            {bodyText2 ? <p className="body-text">{bodyText2}</p> : null}
            <WistiaVideo id={videoID} layoutTemplate={layoutTemplates.stacked} />
            <Button className={buttonClasses} onClick={onSubmitHandler}>
              {buttonAvailabilityText}
            </Button>
          </Grid.Column>
          <Grid.Column width={8} className="right-column">
            <h3>What to have ready</h3>
            <List>
              {requirements.map(requirement => (
                <List.Item key={requirement} className="list-item-container">
                  <List.Icon name="check" className="checkmark" />
                  <List.Header className="header">{requirement}</List.Header>
                </List.Item>
              ))}
            </List>
            {needHelpLink && needHelpText ? (
              <Fragment>
                <h3>Need help?</h3>
                <Link to={needHelpLink} className="need-help-link">
                  {needHelpText}
                </Link>
              </Fragment>
            ) : null}
          </Grid.Column>
        </Grid>
      </div>
    </WizardLayout>
  );
};

PageIntroduction.defaultProps = {
  onSubmit: noOp,
};

PageIntroduction.propTypes = {
  step: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export default PageIntroduction;
