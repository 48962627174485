import './CompanyInformationForm.scss';
import { Form, Grid, Icon, Message } from 'semantic-ui-react';
import { LicenseDisplay, StateSelect } from 'components';
import React, { useState } from 'react';
import {
  allState as allIntroState,
  createQueriesHook as createIntroQueries,
} from 'src/utilities/introInformation';
import {
  allState as allLicensesState,
  createQueriesHook as createLicenseQueries,
  getLicenses,
  registerLicense,
  updateLicenseName,
} from 'src/utilities/licenses';

import Checkbox from '../widgets/Checkbox';
import { Formik } from 'formik';
import Input from '../widgets/Input';
import LicenseItem from '../widgets/LicenseItem';
import NextStepButton from '../widgets/NextStepButton';
import { displayMessage } from '../../toasts';
import { stateLookup } from 'src/data/StateOptions';

const validate = () => {
  const errors = {};
  return errors;
};

const makeAsync = target => (...args) => {
  (async () => await target(...args))();
};

const CompanyInfoForm = ({
  nextStep,
  initialValues,
  working,
  onSubmit,
  error,
  stepCompleted,
}) => {
  const { introState } = allIntroState();
  const { values } = introState;
  const introQueries = createIntroQueries({ introState });
  const userState = values.state;
  const { collectsLeafAPIKey } = stateLookup(userState);
  const licenseState = { ...initialValues };

  const licenseQueries = createLicenseQueries(allLicensesState());

  let { licenses = [], licenseFormState } = getLicenses();

  const [registrationError, setRegistrationError] = useState('');
  const [registeringLicense, setRegisteringLicense] = useState(false);
  const [activeLicense, setActiveLicense] = useState(licenses[0]);
  const [isTouched, setIsTouched] = useState(false);
  const [isProvidingLeafAPIKey, setIsProvidingLeafAPIKey] = useState(false);

  const showSaveConfirmation = (license, wasJustConfirmed) => {
    const message = wasJustConfirmed
      ? `Confirmed license: ${license}`
      : `License changes saved for license: ${license}`;
    displayMessage(message);
  };

  const isConfirmedLicense = license => licenseState[license];

  const onToggleIsProvidingLeafAPIKey = () => {
    setIsProvidingLeafAPIKey(!isProvidingLeafAPIKey);
  };

  const confirmLicense = async details => {
    setRegistrationError('');
    setRegisteringLicense(true);

    const { success, errors } = await registerLicense(details);

    setRegisteringLicense(false);
    if (success) return true;

    setRegistrationError(errors[0]);
    return false;
  };

  const saveLicense = async values => {
    const confirmingLicense = !isConfirmedLicense(values.license);

    const licenseRegistrationDetails = {
      ...values,
      password: licenseQueries.password,
      email: introQueries.email,
    };

    if (confirmingLicense && !(await confirmLicense(licenseRegistrationDetails))) {
      return;
    }

    if (values.license !== activeLicense) {
      delete licenseState[activeLicense];
      updateLicenseName(licenseFormState, activeLicense, values.license);
    }

    licenseState[values.license] = values;
    onSubmit(licenseState, false);
    setIsTouched(false);
    setActiveLicense(values.license);
    showSaveConfirmation(values.license, confirmingLicense);
  };

  const setTouched = values => {
    setIsTouched(Object.keys(values).length > 0);
  };

  const numPendingLicenses = licenses.length - Object.keys(licenseState).length;

  return (
    <div className="CompanyInformationForm">
      {registeringLicense && (
        <div className="registeringDiv">
          <div className="message">
            <Message icon>
              <Icon loading name="spinner" />
              <Message.Content>
                <Message.Header>Registering license</Message.Header>
                We are attempting to register your license...
              </Message.Content>
            </Message>
          </div>
        </div>
      )}
      <h2>License Details for license {activeLicense}</h2>
      {registrationError && <Message error header={registrationError} />}
      {error && <Message error header={error} />}
      <Grid columns={2} stackable>
        <Grid.Column width={12}>
          <LicenseInfoForm
            isConfirmedLicense={isConfirmedLicense}
            nextStep={nextStep}
            numPendingLicenses={numPendingLicenses}
            introData={introQueries}
            license={activeLicense}
            initialValues={licenseState[activeLicense]}
            working={working}
            onSubmit={makeAsync(saveLicense)}
            setTouched={setTouched}
            showLeafData={collectsLeafAPIKey}
            isProvidingLeafAPIKey={isProvidingLeafAPIKey}
            onToggleIsProvidingLeafAPIKey={onToggleIsProvidingLeafAPIKey}
            error={error}
          />
          <br />
          <NextStepButton nextStep={nextStep} stepCompleted={stepCompleted} />
        </Grid.Column>
        <Grid.Column width={4}>
          <LicenseDisplay
            confirmedLicenses={Object.keys(licenseState)}
            onClick={license => {
              if (
                !isTouched ||
                confirm(`Change to editing ${license}?  Unsaved changes will be lost`)
              ) {
                setActiveLicense(license);
              }
            }}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

const LicenseInfoForm = ({
  isConfirmedLicense,
  introData,
  initialValues,
  working,
  onSubmit,
  setTouched,
  license,
  showLeafData,
  isProvidingLeafAPIKey,
  onToggleIsProvidingLeafAPIKey,
}) => {
  const fieldValues = {
    dba: (initialValues && initialValues.dba) || '',
    addr1: (initialValues && initialValues.addr1) || '',
    addr2: (initialValues && initialValues.addr2) || '',
    city: (initialValues && initialValues.city) || '',
    state: (initialValues && initialValues.state) || introData.state,
    zip: (initialValues && initialValues.zip) || '',
    phone: (initialValues && initialValues.phone) || introData.phone,
    primaryaddr: (initialValues && initialValues.primaryaddr) || '',
    license,
    leafDataApiKey: (initialValues && initialValues.leafDataApiKey) || '',
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={fieldValues}
      validate={values => validate(values)}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        /* and other goodies */
      }) => {
        setTouched(touched);
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Field label="License Number"></Form.Field>
            <LicenseItem
              title={values.license}
              canEdit={false}
              pending={!isConfirmedLicense(values.license)}
            />
            <Input
              label="'Doing-Business-As' DBA (Optional)"
              required={false}
              name="dba"
              value={values.dba}
              placeholder="John's Cultivation Shop"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.dba && touched.dba && errors.dba}
            />
            <Input
              label="Street"
              name="addr1"
              required={true}
              value={values.addr1}
              placeholder="123 Main Street"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.addr1 && touched.addr1 && errors.addr1}
            />
            <Input
              label="Street (Optional)"
              name="addr2"
              value={values.addr2}
              placeholder="Unit #2"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.addr2 && touched.addr2 && errors.addr2}
            />
            <Form.Group widths="equal">
              <Input
                label="City"
                name="city"
                required={true}
                value={values.city}
                placeholder="Seattle"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.city && touched.city && errors.city}
              />
              <StateSelect
                fluid
                label="State"
                required
                name="state"
                id="state"
                value={values.state}
                placeholder="WA"
                onChange={setFieldValue}
                onBlur={handleBlur}
                error={errors.state && touched.state && errors.state}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Input
                label="Zipcode"
                name="zip"
                required={true}
                value={values.zip}
                placeholder="10011"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.zip && touched.zip && errors.zip}
              />
              <Input
                label="Phone"
                name="phone"
                required={true}
                value={values.phone}
                placeholder="(123) 456 1222"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.phone && touched.phone && errors.phone}
              />
            </Form.Group>
            <Checkbox
              label="This is our primary mailing address"
              id="primaryaddr"
              checked={values.primaryaddr}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/*<Form.Checkbox
              label="This address is used for ALL MY LICENSES"
              name="consistentaddr"
              type="checkbox"
              value="true"
              checked={values.consistentaddr}
              id="consistentaddr"
              onChange={handleChange}
              onBlur={handleBlur}
            /> */}
            {showLeafData && (
              <Checkbox
                label="I have a Leaf API Key for this license"
                checked={isProvidingLeafAPIKey}
                onChange={onToggleIsProvidingLeafAPIKey}
                onBlur={handleBlur}
              />
            )}
            {showLeafData && isProvidingLeafAPIKey && (
              <>
                <h3>License # {license} is connected to Leaf Data Systems.</h3>
                <p>
                  In order to use GrowFlow with this license, we will need its Leaf Data
                  API Key.
                </p>
                <Input
                  label="Leaf Data API Key"
                  name="leafDataApiKey"
                  required={true}
                  value={values.leafDataApiKey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.leafDataApiKey &&
                    touched.leafDataApiKey &&
                    errors.leafDataApiKey
                  }
                />
              </>
            )}
            <Form.Button primary type="submit" disabled={working} loading={working}>
              Save License Info
            </Form.Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyInfoForm;
