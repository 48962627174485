import { Button } from 'semantic-ui-react';
import React from 'react';

const NextStepButton = ({ nextStep, stepCompleted, isSkippingStep = false }) => (
  <Button onClick={nextStep} className="next" disabled={!stepCompleted}>
    {isSkippingStep ? 'Skip' : 'Continue To Next Step'}
  </Button>
);

export default NextStepButton;
