import './LicenseItem.scss';

import { Button, Icon } from 'semantic-ui-react';

import React from 'react';

const noOp = () => {};

const LicenseItem = ({ title, onEdit, pending, canEdit = true }) => {
  const handler = canEdit ? onEdit : noOp;

  const onClick = e => {
    e.preventDefault();
    handler();
  };

  return (
    <div key={title} className={`LicenseItem ${pending ? 'pending' : 'not-pending'}`}>
      <Button onClick={onClick}>
        {canEdit && <Icon name="edit" />}
        <span>{title}</span>
      </Button>
    </div>
  );
};

export default LicenseItem;
