import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

function Calendly({ calendlyUrl }) {
  useEffect(() => {
    const calendlyScript = document.querySelector(
      'script[src="https://assets.calendly.com/assets/external/widget.js"]'
    );
    if (!calendlyScript) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('id', 'calendly-script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }
    return () => {
      const head = document.querySelector('head');
      const scriptTag = head.querySelector('#calendly-script');
      head.removeChild(scriptTag);
    };
  }, []);
  return (
    <div
      className="calendly-inline-widget"
      data-url={calendlyUrl}
      style={{ minWidth: '320px', height: '630px' }}
    ></div>
  );
}

Calendly.propTypes = {
  calendlyUrl: PropTypes.string.isRequired,
};

export default Calendly;
