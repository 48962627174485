import { Divider, Form, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import Button from '../widgets/Button';
import { Formik } from 'formik';
import LicenseAssociations from '../widgets/LicenseAssociations';
import { displayMessage } from '../../toasts';
import vehiclemakes from '../../pages/Wizard/DeliveryVehicles/vehiclemakes.json';

const noOp = () => {};

const validate = values => {
  const errors = {};
  if (values.color === '') errors.color = 'Required';
  if (values.make === '') errors.make = 'Required';
  if (values.model === '') errors.model = 'Required';
  if (values.year === '') errors.year = 'Required';
  if (values.platenumber === '') errors.platenumber = 'Required';
  if (values.vinnumber === '') errors.vinnumber = 'Required';

  if (values.licenses.length === 0)
    errors.shouldHaveAtLeastOneLicenseAssigned = 'At least one license must be selected';

  return errors;
};

const handleDropdownChange = (e, { name, value }, setFieldValue) => {
  setFieldValue(name, value);
};

const attemptSaveAndContinueToNextStep = (
  nextInternalStep,
  values,
  onSubmit
) => async () => {
  const errors = validate(values);

  if (Object.keys(errors).length === 0) await onSubmit(values);

  nextInternalStep();
};

const DeliveryVehiclesForm = ({
  canDeleteVehicle = false,
  skippingVehicles,
  skippableSectionBuilder,
  licenses,
  initialValues,
  working,
  onSubmit,
  nextInternalStep,
  nextStep,
  onClose = noOp,
  deleteVehicle,
  introInformation,
}) => {
  const fieldValues = {
    nickname: (initialValues && initialValues.nickname) || '',
    color: (initialValues && initialValues.color) || '',
    make: (initialValues && initialValues.make) || '',
    model: (initialValues && initialValues.model) || '',
    year: (initialValues && initialValues.year) || '',
    platenumber: (initialValues && initialValues.platenumber) || '',
    vinnumber: (initialValues && initialValues.vinnumber) || '',
    licenses: (initialValues && initialValues.licenses) || [],
    id: (initialValues && initialValues.id) || '',
  };

  return (
    <Fragment>
      <h3>Vehicle Details for {introInformation.companyName}</h3>
      <Formik
        initialValues={fieldValues}
        validate={values => validate(values)}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          displayMessage('Vehicle added');
          resetForm();
          onClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) =>
          skippableSectionBuilder(
            <Form onSubmit={handleSubmit} noValidate>
              <Grid columns={2}>
                <Grid.Column width={11}>
                  <Form.Input
                    fluid
                    label="Nickname (optional)"
                    name="nickname"
                    value={values.nickname}
                    placeholder="Nickname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nickname && touched.nickname && errors.nickname}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      label="Color"
                      name="color"
                      required
                      value={values.color}
                      placeholder="Color"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.color && touched.color && errors.color}
                    />
                    <Form.Select
                      fluid
                      label="Make"
                      required
                      name="make"
                      id="make"
                      options={vehiclemakes}
                      value={values.make}
                      placeholder="Make"
                      onChange={(e, selectData) => {
                        handleDropdownChange(e, selectData, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      error={errors.make && touched.make && errors.make}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      label="Model"
                      name="model"
                      required
                      value={values.model}
                      placeholder="Model"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.model && touched.model && errors.model}
                    />
                    <Form.Input
                      fluid
                      label="Year"
                      name="year"
                      type="text"
                      pattern="[0-9]{4}"
                      maxLength="4"
                      required
                      value={values.year}
                      placeholder="Year"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.year && touched.year && errors.year}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      label="Plate Number"
                      name="platenumber"
                      required
                      value={values.platenumber}
                      placeholder="Plate Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.platenumber && touched.platenumber && errors.platenumber
                      }
                    />
                    <Form.Input
                      fluid
                      label="VIN Number"
                      name="vinnumber"
                      required
                      value={values.vinnumber}
                      placeholder="VIN Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.vinnumber && touched.vinnumber && errors.vinnumber}
                    />
                  </Form.Group>
                  {!deleteVehicle ? (
                    <Fragment>
                      <Form.Group widths="equal">
                        <Form.Button
                          disabled={working}
                          loading={working}
                          size="large"
                          type="submit"
                          className="button-container add-vehicle"
                        >
                          + Submit Vehicle
                        </Form.Button>
                        <Form.Button
                          size="large"
                          type="button"
                          onClick={attemptSaveAndContinueToNextStep(
                            nextInternalStep,
                            values,
                            onSubmit
                          )}
                          className="button-container continue-step"
                        >
                          {"I've Added all my Vehicles"}
                        </Form.Button>
                      </Form.Group>
                    </Fragment>
                  ) : (
                    <Form.Group widths="equal">
                      <Form.Button
                        disabled={working}
                        loading={working}
                        size="large"
                        type="submit"
                        className="button-container add-vehicle"
                      >
                        Save &amp; Continue
                      </Form.Button>
                      <Form.Button
                        size="large"
                        type="button"
                        onClick={onClose}
                        className="button-container cancel"
                      >
                        Cancel Changes
                      </Form.Button>
                      <Form.Button
                        size="large"
                        type="button"
                        onClick={() => {
                          if (!canDeleteVehicle) {
                            displayMessage(
                              'Vehicle cannot be deleted because it is in use by an employee setup as a driver on deliveries'
                            );
                            return;
                          }
                          deleteVehicle(values.id);
                          onClose();
                        }}
                        className="button-container delete"
                      >
                        Delete Vehicle
                      </Form.Button>
                    </Form.Group>
                  )}
                </Grid.Column>
                <Grid.Column width={5}>
                  <p>Associated Licenses</p>

                  <Divider />

                  <LicenseAssociations
                    errors={errors}
                    licenses={licenses}
                    associatedLicenses={values.licenses}
                    licenseAssignmentRequired={true}
                    associationFieldName="licenses"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid.Column>
              </Grid>
            </Form>
          )
        }
      </Formik>
      <br />
      {skippingVehicles && (
        <Grid>
          <Grid.Column width="5">
            <Button onClick={nextStep}>Skip</Button>
          </Grid.Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default DeliveryVehiclesForm;
