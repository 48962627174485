import React from 'react';
import { nextStepGenerator } from '../../utilities/steps';

const NextStepProvider = ({ children }) => {
  const nextStep = nextStepGenerator();

  const childrenWithProps = children
    ? React.Children.map(
        Array.isArray(children) ? children.filter(x => !!x) : [children],
        child => {
          // Only add nextStep to components, not DOM elements
          if (typeof child.type === 'function') {
            return React.cloneElement(child, { nextStep });
          } else {
            return child;
          }
        }
      )
    : children;

  return childrenWithProps ? childrenWithProps : <></>;
};

export default NextStepProvider;
