import { Form, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import DateInput from '../widgets/DateInput';
import Input from '../widgets/Input';

const itemOptions = values =>
  values.map(val => ({
    id: val.id,
    text: val.name,
    value: val.id,
  }));

const createChangeHandler = (setFieldValue, fieldName) => (e, { value }) => {
  setFieldValue(fieldName, value);
};

const createDateChangeHandler = (fieldName, setFieldValue) => (e, { value }) => {
  setFieldValue(fieldName, value === null ? '' : value);
};

export const createFields = ({ itemFieldName, itemLabel }) => ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  strains,
  rooms,
}) => {
  const dateOfBirth = createDateChangeHandler('dateOfBirth', setFieldValue);

  const changeStrain = createChangeHandler(setFieldValue, 'strainId');

  const changeRoom = createChangeHandler(setFieldValue, 'roomId');

  return (
    <Fragment>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Form.Group widths="equal">
              <DateInput
                fluid
                required
                label="Date of Birth"
                name="dateOfBirth"
                value={values.dateOfBirth}
                placeholder="Date Of Birth"
                onChange={dateOfBirth}
                onBlur={handleBlur}
                error={errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
              />
              <Form.Dropdown
                className="Input"
                error={errors.strainId && touched.strainId && errors.strainId}
                search
                selection
                fluid
                id="strainId"
                name="strainId"
                label="Strain"
                onBlur={handleBlur}
                onChange={changeStrain}
                options={itemOptions(strains)}
                placeholder="Strain"
                value={values.strainId}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Group widths="equal">
              <Input
                fluid
                label={itemLabel}
                name={itemFieldName}
                value={values[itemFieldName]}
                placeholder={itemLabel}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors[itemFieldName] && touched[itemFieldName] && errors[itemFieldName]
                }
              />
              <Form.Dropdown
                className="Input"
                error={errors.roomId && touched.roomId && errors.roomId}
                search
                selection
                fluid
                id="stage"
                name="stage"
                label="Room..."
                onBlur={handleBlur}
                onChange={changeRoom}
                options={itemOptions(rooms)}
                placeholder="Choose a room"
                value={values.roomId}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export const SeedGroupFields = createFields({
  itemFieldName: 'seedCount',
  itemLabel: 'Seed Count',
});

export const CloneGroupFields = createFields({
  itemFieldName: 'cloneCount',
  itemLabel: 'Clone Count',
});

export const BulkFlowerGroupFields = createFields({
  itemFieldName: 'weightInGrams',
  itemLabel: 'Weight in Grams',
});

export const ShakeTrimGroupFields = createFields({
  itemFieldName: 'weightInGrams',
  itemLabel: 'Weight in Grams',
});
