import { Button, Modal } from 'semantic-ui-react';
import React from 'react';

const YesNoModal = ({ isOpen, title, onYes, onNo }) => (
  <Modal open={isOpen}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Content>
      <Button positive onClick={onYes}>
        Yes
      </Button>
      <Button negative onClick={onNo}>
        No
      </Button>
    </Modal.Content>
  </Modal>
);

export default YesNoModal;
