import './DateInput.scss';
import { defaultDateInputFormat as DEFAULT_DATE_FORMAT } from '../../utilities/dates';
import React from 'react';
import { DateInput as SemanticDateInput } from 'semantic-ui-calendar-react';

const undefinedOrDefault = (val, defaultValue) =>
  val === undefined ? defaultValue : val;

const DateInput = ({
  className,
  clearable,
  closable,
  dateFormat,
  error,
  fluid,
  label,
  maxDate,
  name,
  onBlur,
  onChange,
  required,
  startMode,
  value,
}) => (
  <SemanticDateInput
    className={className || ''}
    clearable={!!clearable}
    closable={undefinedOrDefault(closable, true)}
    dateFormat={undefinedOrDefault(dateFormat, DEFAULT_DATE_FORMAT)}
    error={error}
    fluid={undefinedOrDefault(fluid, true)}
    label={label || null}
    maxDate={undefinedOrDefault(maxDate, null)}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    required={!!required}
    startMode={undefinedOrDefault(startMode, 'day')}
    value={value}
  />
);

export default DateInput;
