import React from 'react';
import classes from 'classnames';
import config from '../../config';

const spanClasses = () =>
  classes({
    'version-number': !config.isProd,
    'version-number-hidden': config.isProd,
  });

const VersionNumber = () => <span className={spanClasses()}>build: {config.commit}</span>;

export default VersionNumber;
