import { Form, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import React from 'react';
import { StateOptions } from '../../data/StateOptions';
import { autoSigninExistingUser } from '../../utilities/customers';

const requiredFields = [
  'companyName',
  'fullName',
  'email',
  'state',
  'phone',
  'numEmployees',
];

// validation rules
const validate = values => {
  const errors = {};

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (!values.email.includes('@')) {
    errors.email = 'Invalid Format';
  }
  return errors;
};

const handleDropdownChange = (e, { name, value }, setFieldValue) => {
  setFieldValue(name, value);
};

const SignupForm = ({ initialValues, working, onSubmit, error }) => {
  const fieldValues = {
    fullName: (initialValues && initialValues.fullName) || '',
    email: (initialValues && initialValues.email) || '',
    companyName: (initialValues && initialValues.companyName) || '',
    phone: (initialValues && initialValues.phone) || '',
    numEmployees: (initialValues && initialValues.numEmployees) || '',
    state: (initialValues && initialValues.state) || '',
  };

  const validateEmail = handleBlur => e => {
    // eslint-disable-next-line prefer-destructuring
    const value = e.target.value;
    handleBlur(e);
    autoSigninExistingUser(value);
  };

  return (
    <>
      {error && <Message error header={error} />}
      <Formik
        initialValues={fieldValues}
        validate={values => validate(values)}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} noValidate className="signupForm">
            <Form.Input
              fluid
              label="Full Name"
              required
              name="fullName"
              value={values.fullName}
              placeholder="John Smith"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.fullName && touched.fullName && errors.fullName}
            />
            <Form.Input
              fluid
              label="Work Email"
              required
              name="email"
              value={values.email}
              placeholder="joe@grower.com"
              onChange={handleChange}
              onBlur={validateEmail(handleBlur)}
              error={errors.email && touched.email && errors.email}
            />
            <Form.Input
              fluid
              label="Company Name"
              required
              name="companyName"
              value={values.companyName}
              placeholder="Joe's Shop"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.companyName && touched.companyName && errors.companyName}
            />
            <Form.Dropdown
              fluid
              selection
              label="State"
              required
              name="state"
              id="state"
              options={StateOptions.map(({ value, text }) => ({ value, text }))}
              value={values.state}
              placeholder="State"
              onChange={(e, selectData) =>
                handleDropdownChange(e, selectData, setFieldValue)
              }
              onBlur={handleBlur}
              error={errors.state && touched.state && errors.state}
            />
            <Form.Input
              fluid
              label="Phone Number"
              required
              name="phone"
              value={values.phone}
              placeholder="420-222-1113"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone && touched.phone && errors.phone}
            />
            <Form.Input
              fluid
              label="Number of Employees"
              required
              name="numEmployees"
              value={values.numEmployees}
              placeholder="42"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.numEmployees && touched.numEmployees && errors.numEmployees}
            />
            <Form.Button fluid primary type="submit" disabled={working} loading={working}>
              Try 1 Month Free
            </Form.Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
