import React from 'react';

function DocumentIcon({ className }) {
  return (
    <svg
      className={className}
      width="77px"
      height="77px"
      viewBox="0 0 77 77"
      version="1.1"
    >
      <g
        id="Sign-Up/Onboarding-Process---Grow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="04---Company-Form---01---Company-Info-Lander-(1)"
          transform="translate(-376.000000, -133.000000)"
        >
          <g id="Content" transform="translate(375.000000, 133.000000)">
            <g id="Section-Header">
              <g id="Group-14" transform="translate(1.000000, 0.000000)">
                <path
                  d="M23.7273773,39.9116316 L19.3277612,45.4973812 C19.1869734,45.7022137 18.9117975,45.7509833 18.710215,45.6079257 C18.665419,45.5819152 18.6270223,45.5428995 18.5982248,45.4973812 L16.0992429,42.9581085 C15.1617246,42.0054748 13.6418573,42.0054748 12.7043391,42.9613598 C11.7636211,43.9139935 11.7668209,45.4616168 12.7043391,46.4142504 L17.5039203,51.2912145 C17.9550809,51.7431466 18.5630279,52 19.1997723,52 L19.3309609,52 C20.0125014,51.9642356 20.6492459,51.632602 21.0748087,51.0896334 L27.4742504,42.9613598 C28.3029781,41.9111869 28.1333929,40.3765688 27.0966833,39.5344797 C26.0631735,38.6923905 24.5529053,38.8647099 23.7241776,39.9148829 L23.7273773,39.9116316 Z"
                  id="Fill-1"
                  fill="#32C1A2"
                ></path>
                <path
                  d="M27.0987222,21.5353246 C26.0621199,20.6935122 24.5552073,20.8625247 23.7265653,21.9156029 L19.3274044,27.4995174 C19.1866313,27.7042826 18.9082844,27.7530362 18.7067228,27.6132759 C18.6651308,27.5807735 18.6267381,27.5417706 18.5979436,27.4995174 L16.0992202,24.961079 C15.161799,24.0087583 13.6388894,24.0087583 12.7014683,24.961079 C11.7640471,25.9166499 11.7672464,27.4605145 12.7046676,28.4128352 L17.5037523,33.2881972 C17.9548662,33.7432309 18.5627503,34 19.1994288,34 L19.3306038,34 C20.0120738,33.9609971 20.645553,33.6327227 21.0742712,33.0899324 L27.4730508,24.9643292 C28.3016927,23.9145013 28.1353244,22.3803874 27.0987222,21.5353246 Z"
                  id="Fill-3"
                  fill="#32C1A2"
                ></path>
                <path
                  d="M44,30.5 C44,29.12 42.9082729,28 41.5631092,28 L33.4368908,28 C32.0917271,28 31,29.12 31,30.5 C31,31.88 32.0917271,33 33.4368908,33 L41.55986,33 C42.9050237,33 43.9967508,31.8833333 44,30.5033333 L44,30.5 Z"
                  id="Fill-5"
                  fill="#32C1A2"
                ></path>
                <path
                  d="M33.5,45 C32.12,45 31,46.12 31,47.5 C31,48.88 32.12,50 33.5,50 L38.5,50 C39.88,50 41,48.88 41,47.5 C41,46.12 39.88,45 38.5,45 L33.5,45 Z"
                  id="Fill-7"
                  fill="#32C1A2"
                ></path>
                <path
                  d="M57.5999228,23.2572083 L57.5999228,49.9762083 C57.5967228,50.4189583 57.9487223,50.7782917 58.3903217,50.7815 C58.6015214,50.7815 58.8063212,50.7012917 58.956721,50.5505 C60.5183189,49.0169167 62.9727156,46.613875 63.7567145,45.87275 C63.9167143,45.728375 64.0031142,45.5198333 63.9999142,45.304875 L63.9999142,20.5750417 C63.9999142,19.7248333 63.6639146,18.9099167 63.0623155,18.30675 L45.7407387,0.936833333 C45.1391395,0.336875 44.3263406,0 43.4783417,0 L6.39999142,0 C2.86719616,0 0,2.87145833 0,6.41666667 L0,70.5833333 C0,74.1253333 2.86719616,77 6.39999142,77 L42.6015429,77 C43.0367423,76.9935833 43.3919418,76.643875 43.3951418,76.2043333 C43.4335418,74.9530833 43.7151414,72.736125 43.9039411,71.4945 C43.9647411,71.0549583 43.6639415,70.6507083 43.228742,70.5929583 C43.1871421,70.5865417 43.1487421,70.5833333 43.1103422,70.5833333 L7.99998927,70.5833333 C7.11679046,70.5833333 6.39999142,69.8646667 6.39999142,68.9791667 L6.39999142,8.02083333 C6.39999142,7.13533333 7.11679046,6.41666667 7.99998927,6.41666667 L40.7999453,6.41666667 C41.2415447,6.41666667 41.5999442,6.776 41.5999442,7.21875 L41.5999442,16.0416667 C41.5999442,19.5836667 44.4671404,22.4583333 47.9999356,22.4583333 L56.7999238,22.4583333 C57.2415233,22.4583333 57.5967228,22.8144583 57.5999228,23.2572083"
                  id="Fill-9"
                  fill="#32C1A2"
                ></path>
                <g id="Group-13" transform="translate(48.000000, 47.000000)">
                  <mask id="mask-2" fill="white">
                    <polygon
                      id="path-1"
                      points="0.00145078433 0.998711111 28.8064 0.998711111 28.8064 29.9987111 0.00145078433 29.9987111"
                    ></polygon>
                  </mask>
                  <g id="Clip-12"></g>
                  <path
                    d="M26.99072,2.79671111 C24.60672,0.399377778 20.74112,0.399377778 18.35392,2.79671111 L2.07552,19.1913778 C1.85152,19.4137111 1.70112,19.7004889 1.63392,20.0130444 L0.03392,28.0686 C-0.14208,28.9386 0.41472,29.7892667 1.28512,29.9697111 C1.39072,29.9890444 1.49632,29.9987111 1.60512,29.9987111 C1.71392,29.9987111 1.82272,29.9890444 1.92512,29.9729333 L9.92512,28.3618222 C10.23872,28.2973778 10.52352,28.1427111 10.74752,27.9203778 L27.02592,11.5257111 C29.40992,9.11548889 29.40032,5.21015556 27.00352,2.8096 L26.99072,2.79671111 Z"
                    id="Fill-11"
                    fill="#32C1A2"
                    mask="url(#mask-2)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DocumentIcon;
