import 'semantic-ui-css/semantic.min.css';
import '../../styles/global.scss';

import { Container, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { Header, NextStepProvider, Page, WizardNav } from 'components';
import React, { useEffect } from 'react';
import { getUser, logout, useCheckAuthTokenExpired } from '../../utilities/auth';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { getFormStateLoaded } from '../../utilities/formstate';
import { useResume } from '../../utilities/resumeHook';

const WizardLayout = ({ children, className }) => {
  useCheckAuthTokenExpired();

  const resume = useResume();
  const user = getUser();
  const isStateLoaded = getFormStateLoaded();

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    if (!isStateLoaded && resume.noResumeAttemptsLeft()) {
      // eslint-disable-next-line no-console
      console.error('Logging the user out due to running out of attempts to load state');
      logout();
      return;
    }

    if (!isStateLoaded) resume.run();
  }, [isStateLoaded]);

  if (!user) {
    return <div />;
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Page>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Grid columns={2} stackable>
        <Grid.Column width={4}>
          <WizardNav />
        </Grid.Column>
        <Grid.Column width={12}>
          <Container text className="padding-top main-container">
            <main className={className}>
              {isStateLoaded ? (
                <NextStepProvider>{children}</NextStepProvider>
              ) : (
                <Dimmer active inverted page>
                  <Loader size="massive">Loading</Loader>
                </Dimmer>
              )}
            </main>
          </Container>
        </Grid.Column>
      </Grid>
    </Page>
  );
};

WizardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WizardLayout;
