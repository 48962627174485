import { Form, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import Input from '../widgets/Input';

const checkStateChangeHandler = (value, setFieldValue) => (e, { name }) => {
  setFieldValue(name, value);
};

const purposeOptions = values => Object.values(values);

export const PlantRoomFields = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  purposes,
}) => {
  const changePurpose = setFieldValue => (e, { value }) => {
    setFieldValue('purpose', value);
  };

  return (
    <Fragment>
      <Grid columns={2}>
        <Grid.Column width={12}>
          <Form.Group widths="equal">
            <Input
              fluid
              label="Room Name"
              name="name"
              value={values.name}
              placeholder="Room Name..."
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name && errors.name}
            />

            <Form.Dropdown
              className="Input"
              error={errors.purpose && touched.purpose && errors.purpose}
              clearable
              search
              selection
              fluid
              id="purpose"
              name="purpose"
              label="Purpose"
              onBlur={handleBlur}
              onChange={changePurpose(setFieldValue)}
              options={purposeOptions(purposes)}
              placeholder="Choose a purpose"
              value={values.purpose}
            />
          </Form.Group>
        </Grid.Column>
      </Grid>
      <Form.Group inline>
        <label>Make Room Default for selected Room Purpose? </label>

        <Form.Radio
          label="Yes"
          type="radio"
          name="isDefault"
          id="isDefaultForPurposeYes"
          checked={values.isDefault}
          onChange={checkStateChangeHandler(true, setFieldValue)}
        />

        <Form.Radio
          label="No"
          name="isDefault"
          id="isDefaultForPurposeNo"
          type="radio"
          checked={!values.isDefault}
          onChange={checkStateChangeHandler(false, setFieldValue)}
        />
      </Form.Group>
    </Fragment>
  );
};

export const InventoryRoomFields = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
}) => (
  <Fragment>
    <Grid columns={2}>
      <Grid.Column width={12}>
        <Form.Group widths="equal">
          <Input
            fluid
            label="Room Name"
            name="name"
            value={values.name}
            placeholder="Room Name..."
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name && touched.name && errors.name}
          />
        </Form.Group>
      </Grid.Column>
    </Grid>
  </Fragment>
);
