import {
  BulkFlowerGroupFields,
  CloneGroupFields,
  SeedGroupFields,
  ShakeTrimGroupFields,
} from './PlantInventoryFields';
import { Button, Modal } from 'semantic-ui-react';
import {
  validateBulkFlowerGroup,
  validateCloneGroup,
  validateSeedGroup,
  validateShakeTrimGroup,
} from '../../utilities/plantInventory';
import React from 'react';
import { form } from '../../utilities/forms';

const createForm = ({ fieldsView: FieldsView, validator, showCancel }) => ({
  cancelText,
  item,
  onCancel,
  onSubmit,
  rooms,
  strains,
  submitText,
}) =>
  form({
    cancelText,
    enableReinitialize: true,
    initialValues: item,
    onCancel,
    onSubmit,
    showCancel,
    renderFields: fieldProps => (
      <FieldsView {...fieldProps} strains={strains} rooms={rooms} />
    ),
    submitText,
    validator,
  });

const createModalForm = ({ titleTemplate, Form }) => ({
  deleteHandler,
  onClose,
  item,
  saveHandler,
  license,
  rooms,
  strains,
  includeDelete = true,
}) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>{titleTemplate(item)}</h2>
      <h3>On License # {license} </h3>
    </Modal.Header>
    <Modal.Content>
      <Form
        license={license}
        submitText="Save"
        cancelText="Cancel"
        onSubmit={saveHandler}
        onCancel={onClose}
        item={item}
        strains={strains}
        rooms={rooms}
      />
      {includeDelete && <Button onClick={deleteHandler}>Delete</Button>}
    </Modal.Content>
  </Modal>
);

export const createFormBuilder = ({ fieldsView, validator }) => ({
  cancelable: createForm({ fieldsView, validator, showCancel: true }),
  nonCancelable: createForm({ fieldsView, validator, showCancel: false }),
});

const seedGroupForms = createFormBuilder({
  fieldsView: SeedGroupFields,
  validator: validateSeedGroup,
});

const cloneGroupForms = createFormBuilder({
  fieldsView: CloneGroupFields,
  validator: validateCloneGroup,
});

const bulkFlowerGroupForms = createFormBuilder({
  fieldsView: BulkFlowerGroupFields,
  validator: validateBulkFlowerGroup,
});

const shakeTrimGroupForms = createFormBuilder({
  fieldsView: ShakeTrimGroupFields,
  validator: validateShakeTrimGroup,
});

export const SeedGroupForm = seedGroupForms.nonCancelable;

export const ModalEditSeedGroupForm = createModalForm({
  Form: seedGroupForms.cancelable,
  titleTemplate: item => `Edit Seed Group ${item.name}`,
});

export const ModalAddSeedGroupForm = createModalForm({
  Form: seedGroupForms.cancelable,
  titleTemplate: () => 'Add Seed Group',
  includeDelete: false,
});

export const CloneGroupForm = cloneGroupForms.nonCancelable;

export const ModalEditCloneGroupForm = createModalForm({
  Form: cloneGroupForms.cancelable,
  titleTemplate: item => `Edit Clone Group ${item.name}`,
});

export const ModalAddCloneGroupForm = createModalForm({
  Form: cloneGroupForms.cancelable,
  titleTemplate: () => 'Add Clone Group',
  includeDelete: false,
});

export const BulkFlowerGroupForm = bulkFlowerGroupForms.nonCancelable;

export const ModalEditBulkFlowerGroupForm = createModalForm({
  Form: bulkFlowerGroupForms.cancelable,
  titleTemplate: item => `Bulk Flower Group ${item.name}`,
});

export const ModalAddBulkFlowerGroupForm = createModalForm({
  Form: bulkFlowerGroupForms.cancelable,
  titleTemplate: () => 'Add Bulk Flower Group',
  includeDelete: false,
});

export const ShakeTrimGroupForm = shakeTrimGroupForms.nonCancelable;

export const ModalEditShakeTrimGroupForm = createModalForm({
  Form: shakeTrimGroupForms.cancelable,
  titleTemplate: item => `Shake Trim Group ${item.name}`,
});

export const ModalAddShakeTrimGroupForm = createModalForm({
  Form: shakeTrimGroupForms.cancelable,
  titleTemplate: () => 'Shake Trip Group',
  includeDelete: false,
});
