import { ProgressBar, ProgressItems } from 'components';
import {
  getCurrentStepNum,
  getMenu,
  getStepCompletionPercentage,
} from '../../utilities/steps';
import { Container } from 'semantic-ui-react';
import { Location } from '@reach/router';
import React from 'react';

const WizardNav = props => {
  const { location } = props;

  const menu = getMenu();
  const currentStepNum = getCurrentStepNum(location);
  const {
    completedPercentage,
    skippedPercentage,
    totalPercentage,
  } = getStepCompletionPercentage();

  return (
    <Container className="bg-color-medium-gray left-container">
      <h3>GrowFlow Setup</h3>
      <ProgressBar
        completedPercentage={completedPercentage}
        skippedPercentage={skippedPercentage}
        totalPercentage={totalPercentage}
      />
      <ProgressItems menu={menu} currentStepNum={currentStepNum} />
    </Container>
  );
};

export default () => <Location>{props => <WizardNav {...props} />}</Location>;
