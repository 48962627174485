import { Form, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import React from 'react';

// validation rules
const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.email.includes('@')) {
    errors.email = 'Invalid Format';
  }
  return errors;
};

const AuthForm = ({ initialValues, working, onSubmit, error }) => {
  const fieldValues = {
    email: (initialValues && initialValues.email) || '',
  };

  return (
    <>
      {error && <Message error header={error} />}
      <Formik
        initialValues={fieldValues}
        validate={values => validate(values)}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Input
              fluid
              label="Email"
              required
              name="email"
              value={values.email}
              placeholder="joe@grower.com"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email && errors.email}
            />
            <Form.Button primary type="submit" disabled={working} loading={working}>
              Login
            </Form.Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AuthForm;
