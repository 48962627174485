import { Container, Divider } from 'semantic-ui-react';
import Item from './Item';
import React from 'react';

const ItemsList = ({ title, titleTemplate, items, onEdit }) => (
  <Container>
    {title}
    <Divider />
    {items.map(item => (
      <Item key={item.id} id={item.id} title={titleTemplate(item)} onEdit={onEdit} />
    ))}
  </Container>
);

export default ItemsList;
