import './WistiaVideo.scss';
import React, { useEffect } from 'react';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utilities/criteria';
import { Grid } from 'semantic-ui-react';

const baseURL = 'https://fast.wistia.com';
const loadWistiaURL = 'https://fast.wistia.com/assets/external/E-v1.js';
const scriptID = 'wistiaScript';

const createEmbedLinks = id => ({
  jsonp: `${baseURL}/embed/medias/${id}.jsonp`,
  swatch: `${baseURL}/embed/medias/${id}/swatch`,
  asyncEmbedClass: `wistia_embed wistia_async_${id} seo=false videoFoam=true`,
});

const wistiaLoaded = () => isNotNullOrUndefined(document.getElementById(scriptID));

const embedWistia = () => {
  const wistiaScript = document.createElement('script');

  wistiaScript.id = scriptID;
  wistiaScript.type = 'text/javascript';
  wistiaScript.src = loadWistiaURL;
  wistiaScript.async = true;

  document.body.appendChild(wistiaScript);
};

const Video = ({ id }) => {
  const links = createEmbedLinks(id);

  useEffect(() => {
    if (wistiaLoaded()) return;

    embedWistia();
  }, []);

  return (
    <div className="WistiaVideo">
      <div className="responsive-padding">
        <div className="responsive-wrapper">
          <div className={links.asyncEmbedClass}>
            <div className="wistia-swatch">
              <img className="img-swatch" src={links.swatch} alt="" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const gridLayoutTemplate = ({ id, blurb }) => (
  <Grid columns="2" stackable>
    <Grid.Row>
      <Grid.Column width="8">
        <h3>{blurb}</h3>
      </Grid.Column>
      <Grid.Column width="8">
        <Video id={id} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const stackedLayoutTemplate = ({ id, blurb }) => (
  <div>
    <h3>{blurb}</h3>
    <Video id={id} />
  </div>
);

export const layoutTemplates = {
  grid: gridLayoutTemplate,
  stacked: stackedLayoutTemplate,
};

export const WistiaVideo = ({
  id,
  blurb = 'Watch this video to learn how to complete this section',
  layoutTemplate = layoutTemplates.grid,
}) => (isNullOrUndefined(id) ? null : layoutTemplate({ id, blurb }));
