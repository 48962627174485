import { Button, Divider, Form, Grid, Icon, Message, Search } from 'semantic-ui-react';
import React, { Fragment, useState } from 'react';

import { Formik } from 'formik';
import StrainList from '../../data/StrainList.json';

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
};

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);
function escapeRegExp(string) {
  return string && reHasRegExpChar.test(string)
    ? string.replace(reRegExpChar, '\\$&')
    : string || '';
}

const StrainsForm = ({
  working,
  onSubmit,
  error,
  introInformation,
  nextInternalStep,
  nextStep,
  addedStrains,
  createDeleteStrainHandler,
  skippableSectionBuilder,
  skippingStrains,
}) => {
  let [lastStrainAdded, setLastStrainAdded] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearchChange = (e, { name, value }, setFieldValue) => {
    setFieldValue(name, value);

    setTimeout(() => {
      if (value.length < 1) return setResults([]);

      const re = new RegExp(escapeRegExp(value), 'i');
      const isMatch = result => re.test(result.title);
      const filteredAndSortedResults = StrainList.filter(isMatch).sort(
        (a, b) => b.rank - a.rank
      );
      setResults(filteredAndSortedResults);
    }, 300);

    setFieldValue(name, value);
  };

  const handleResultSelect = (e, { name, result }, setFieldValue) => {
    onSubmit({ name: result.title });
    setFieldValue(name, '');
  };

  return (
    <Fragment>
      {skippableSectionBuilder(
        <Fragment>
          <h3>Strain Details for {introInformation.companyName}</h3>
          <Grid className="strains-grid-container">
            <Grid.Row>
              <Grid.Column width={10}>
                {error && <Message error header={error} />}

                <Formik
                  initialValues={{ name: '' }}
                  validate={values => validate(values)}
                  onSubmit={(values, { resetForm }) => {
                    onSubmit(values);
                    resetForm();
                    setLastStrainAdded(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate className="strain-form">
                      <Form.Group widths="16">
                        <Search
                          showNoResults={false}
                          name="name"
                          placeholder="Name"
                          value={values.name}
                          onSearchChange={(e, selectData) => {
                            handleSearchChange(e, selectData, setFieldValue);
                          }}
                          onResultSelect={(e, selectData) => {
                            handleResultSelect(e, selectData, setFieldValue);
                          }}
                          onBlur={handleBlur}
                          results={results}
                          error={errors && touched.name && errors.name}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Button
                          disabled={working}
                          loading={working}
                          size="large"
                          type="submit"
                          className="button-container add-vehicle"
                        >
                          + Submit Strain
                        </Form.Button>
                        <Form.Button
                          size="large"
                          type="button"
                          onClick={nextInternalStep}
                          className="button-container continue-step"
                        >
                          Review My Strains
                        </Form.Button>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Grid.Column>
              <Grid.Column width={6} className="strains-container">
                <Grid.Row>
                  <h3>Added Strains</h3>
                </Grid.Row>
                <Divider />
                <Grid className="strain-container">
                  {addedStrains.map(({ id: strainId, name }) => (
                    <Grid.Row key={strainId} className="strain-list-container">
                      <Grid.Column className="strain-button-container">
                        <Button icon labelPosition="right">
                          <Icon name="pencil" />
                          {name}
                        </Button>
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          icon
                          className="trash-button"
                          onClick={createDeleteStrainHandler(strainId)}
                        >
                          <Icon name="trash" />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                {!error && lastStrainAdded !== false && (
                  <Message positive>
                    <Message.Header>
                      Successfully added "{lastStrainAdded.name}."
                    </Message.Header>
                    <p>(You can edit or delete this strain in the next step.)</p>
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      )}
      <br />
      {skippingStrains && <Button onClick={nextStep}>Skip</Button>}
    </Fragment>
  );
};

export default StrainsForm;
