import { Button, Grid } from 'semantic-ui-react';
import React, { useState } from 'react';
import {
  getUser,
  isImpersonatingUser,
  logout,
  stopImpersonating,
} from '../../utilities/auth';
import { Link } from 'gatsby';
import Logo from './Logo';
import { getFormState } from '../../utilities/formstate';
import { isGrowFlowEmployee } from '../../utilities/users';

const getValue = (state, key) => state && state.values && state.values[key];

const Header = () => {
  const [open, setOpen] = useState(false);

  const formState = getFormState('introInformation');
  const fullName = getValue(formState, 'fullName');
  const companyName = getValue(formState, 'companyName');

  const loggedInFormState = getFormState('introInformation', false);
  const loggedInFullName = getValue(loggedInFormState, 'fullName');
  const loggedInCompanyName = getValue(loggedInFormState, 'companyName');

  const toggleOpen = () => setOpen(!open);
  const user = getUser();
  const isAGrowFlowEmployee = isGrowFlowEmployee(user && user.name);
  const isImpersonating = isImpersonatingUser();
  const stopImpersonatingUser = stopImpersonating();

  return (
    <header className="bg-color-purple-dark onboarding">
      <Grid columns={3} stackable>
        <Grid.Column width={8}>
          <Link to="/">
            <Logo />
          </Link>
        </Grid.Column>
        {isImpersonating && (
          <Grid.Column width={5} floated="right" style={{ paddingBottom: 0 }}>
            <div className="account-info">
              <div className="names-container">
                <p className="full-name">Running As: {fullName}</p>
                <p className="company-name">{companyName}</p>
              </div>
              <div className="icon-container">
                <Button onClick={stopImpersonatingUser}>Stop</Button>
              </div>
            </div>
          </Grid.Column>
        )}
        <Grid.Column width={3} floated="right" style={{ paddingBottom: 0 }}>
          <div className="account-info">
            <div className="names-container">
              <p className="full-name">{loggedInFullName}</p>
              <p className="company-name">{loggedInCompanyName}</p>
            </div>
            <div className="icon-container" onClick={toggleOpen}>
              <i className="angle down icon"></i>
            </div>
            {open ? (
              <div className="dropdown-container">
                <a href="#" onClick={logout}>
                  Logout
                </a>
                {isAGrowFlowEmployee ? <Link to="/admin">Admin</Link> : null}
              </div>
            ) : null}
          </div>
        </Grid.Column>
      </Grid>
    </header>
  );
};

export default Header;
