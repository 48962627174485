import './ProgressBar.scss';
import { Progress } from 'semantic-ui-react';
import React from 'react';

const ProgressBar = ({ completedPercentage, skippedPercentage, totalPercentage }) => (
  <div className="ProgressBar">
    <Progress percent={completedPercentage} />
    <i>{totalPercentage}% Complete</i>
    <i>
      ({completedPercentage}% Completed / {skippedPercentage}% Skipped)
    </i>
  </div>
);

export default ProgressBar;
