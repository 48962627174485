import './internalStepProgress.scss';

import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

function InternalStepProgress({ stepsConfig, nextStep }) {
  const [step, setStep] = useState(1);
  const currentStep = stepsConfig.find(_step => _step.step === step);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const nextInternalStep = () => {
    setStep(state => state + 1);
    scrollTop();
  };

  const backInternalStep = () => {
    setStep(state => state - 1);
    scrollTop();
  };

  const wrappedComopnent = React.cloneElement(currentStep.component, {
    nextInternalStep,
    backInternalStep,
    nextStep,
  });

  return (
    <Fragment>
      <div className="step-container">
        {stepsConfig
          .sort((a, b) => a.step - b.step)
          .map(currentStep => (
            <div
              className="single-step-container"
              key={currentStep.step}
              onClick={() => setStep(currentStep.step)}
            >
              <div
                className={`single-step-indicator ${
                  step >= currentStep.step ? 'fill' : null
                }`}
              />
              <p className="single-step-label">{currentStep.label}</p>
            </div>
          ))}
      </div>
      {wrappedComopnent}
    </Fragment>
  );
}

InternalStepProgress.propTypes = {
  stepsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      component: PropTypes.shape({}).isRequired,
    }).isRequired
  ).isRequired,
};

export default InternalStepProgress;
