import React from 'react';

function EmailIcon({ className }) {
  return (
    <svg
      className={className}
      width="155px"
      height="145px"
      viewBox="0 0 155 145"
      version="1.1"
    >
      <g
        id="Sign-Up/Onboarding-Process---Grow"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="03---User-Confirm---01---Confirm-Page-(1)"
          transform="translate(-606.000000, -196.000000)"
        >
          <g id="Email-Icon" transform="translate(606.000000, 196.000000)">
            <path
              d="M77.5,83.5 C73.912,83.5 71,86.3766316 71,89.9210526 L71,138.078947 C71,141.629789 73.912,144.5 77.5,144.5 C81.088,144.5 84,141.629789 84,138.078947 L84,89.9210526 C84,86.3766316 81.088,83.5 77.5,83.5"
              id="Fill-1"
              fill="#82D173"
            ></path>
            <path
              d="M48.5,73.5 C44.912,73.5 42,76.4269333 42,80.0333333 L42,115.966667 C42,119.5796 44.912,122.5 48.5,122.5 C52.088,122.5 55,119.5796 55,115.966667 L55,80.0333333 C55,76.4269333 52.088,73.5 48.5,73.5"
              id="Fill-3"
              fill="#82D173"
            ></path>
            <path
              d="M106.5,73.5 C102.9055,73.5 100,76.4269333 100,80.0333333 L100,115.966667 C100,119.5796 102.9055,122.5 106.5,122.5 C110.0945,122.5 113,119.5796 113,115.966667 L113,80.0333333 C113,76.4269333 110.0945,73.5 106.5,73.5"
              id="Fill-5"
              fill="#82D173"
            ></path>
            <g id="Group-9" transform="translate(0.000000, 0.500000)">
              <mask id="mask-2" fill="white">
                <polygon
                  id="path-1"
                  points="0 0.0267625382 35.7880817 0.0267625382 35.7880817 49.6966514 0 49.6966514"
                ></polygon>
              </mask>
              <g id="Clip-8"></g>
              <path
                d="M14.126138,1.06272676 C9.30441972,-1.35592404 3.44157465,0.606377551 1.03396901,5.43064059 C0.350729577,6.79316893 -0.000650704225,8.29260204 -0.000650704225,9.81159297 L-0.000650704225,14.4076814 C-0.0136647887,18.4105159 2.41996901,22.0156746 6.13549014,23.4890306 L12.6425324,26.0967404 C8.79687042,29.4672052 8.19822254,35.2432823 11.2760535,39.3373866 L16.2539408,45.856661 C18.1149549,48.2948696 21.0040817,49.7095522 24.0623915,49.6965136 C26.4634901,49.7095522 28.7734901,48.7968537 30.5173775,47.1474773 L35.1178563,41.2801304 C35.5538282,40.7064342 35.7880817,40.0088719 35.7880817,39.2852324 L35.7880817,15.5746315 C35.7880817,14.4142007 35.1764197,13.3385204 34.1743352,12.758305 L14.126138,1.06272676 Z"
                id="Fill-7"
                fill="#82D173"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M112.296446,20.6745146 C111.863992,20.4295818 111.334719,20.442473 110.908719,20.7131882 L84.0126348,37.5748725 C80.0301812,40.0564278 74.9762733,40.0564278 70.9938197,37.5748725 L44.0977353,20.7131882 C43.4587354,20.3071155 42.6196447,20.5004834 42.2130085,21.132152 C42.077463,21.3513024 42.0000085,21.6091263 42.0000085,21.8733958 L42.0000085,54.2818625 C41.993554,58.8066723 45.6597349,62.4871088 50.1908247,62.5 L104.81563,62.5 C109.34672,62.4871088 113.006446,58.8066723 112.999991,54.2818625 L112.999991,21.8669502 C112.999991,21.3706392 112.728901,20.9130017 112.296446,20.6745146"
              id="Fill-10"
              fill="#82D173"
            ></path>
            <path
              d="M104.809218,0.5 L50.1911489,0.5 C45.653628,0.512908778 41.9939205,4.19836489 42.000375,8.72934596 C41.9939205,8.87134251 42.0713746,9.00688468 42.1875558,9.07788296 L75.3314677,29.8803787 C76.6546424,30.7065404 78.3392696,30.7065404 79.6624443,29.8803787 L112.806356,9.07142857 C112.928992,8.9939759 112.999991,8.86488812 112.999991,8.72934596 C113.006446,4.19836489 109.340284,0.512908778 104.809218,0.5"
              id="Fill-12"
              fill="#82D173"
            ></path>
            <g id="Group-16" transform="translate(119.000000, 0.500000)">
              <mask id="mask-4" fill="white">
                <polygon
                  id="path-3"
                  points="0.208924836 0.0318898437 35.5 0.0318898437 35.5 49.709444 0.208924836 49.709444"
                ></polygon>
              </mask>
              <g id="Clip-15"></g>
              <path
                d="M30.931975,1.49234694 C28.0316417,-0.326530612 24.3998083,-0.4569161 21.383975,1.15986395 L1.80030833,12.7446145 C0.812141667,13.3313492 0.202558333,14.4070295 0.208975,15.5739796 L0.208975,39.2845805 C0.208975,40.00822 0.446391667,40.7188209 0.882725,41.292517 L5.41289167,47.1598639 C7.13255833,48.8222789 9.410475,49.7349773 11.7846417,49.7089002 C14.800475,49.7219388 17.6558917,48.300737 19.4846417,45.8625283 L24.4126417,39.297619 C27.428475,35.2035147 26.831725,29.4404762 23.0458917,26.0765306 L29.4625583,23.4688209 C33.1136417,21.9954649 35.5070583,18.3968254 35.5006417,14.4070295 L35.5006417,9.84353741 C35.5198917,6.44047619 33.7873917,3.27210884 30.931975,1.49234694"
                id="Fill-14"
                fill="#82D173"
                mask="url(#mask-4)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EmailIcon;
