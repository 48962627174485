import { Button, Divider, Grid, Modal } from 'semantic-ui-react';
import React, { Fragment, useState } from 'react';

import DeliveryVehiclesForm from '../Forms/DeliveryVehiclesForm';
import NextStepButton from '../widgets/NextStepButton';

function DeliveryVehiclesConfirmation({
  canDeleteVehicle,
  skippableSectionBuilder,
  licenses,
  initialValues,
  working,
  onSubmit,
  backInternalStep,
  deleteVehicle,
  nextStep,
  introInformation,
  stepCompleted,
}) {
  const [idToEdit, setIdToEdit] = useState('');

  const hideModal = () => setIdToEdit('');

  return (
    <Fragment>
      <h3>Delivery Vehicles for {introInformation.companyName}</h3>
      <Grid columns={2}>
        {initialValues
          ? Object.entries(initialValues).map(([key, value]) => (
              <Grid.Row key={key}>
                <Grid.Column width={12}>
                  <p>{`${value.nickname} ${value.color}, ${value.year} ${value.make} ${value.model}`}</p>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Modal
                    trigger={
                      <Button onClick={() => setIdToEdit(value.id)} fluid>
                        Edit
                      </Button>
                    }
                    onClose={hideModal}
                    open={idToEdit === value.id}
                  >
                    <Modal.Header>Edit Vehicle Details for {value.nickname}</Modal.Header>
                    <Modal.Content>
                      <DeliveryVehiclesForm
                        canDeleteVehicle={canDeleteVehicle(value.id)}
                        skippableSectionBuilder={skippableSectionBuilder}
                        licenses={licenses}
                        initialValues={value}
                        working={working}
                        onClose={hideModal}
                        onSubmit={onSubmit}
                        deleteVehicle={deleteVehicle}
                        introInformation={introInformation}
                      />
                    </Modal.Content>
                  </Modal>
                </Grid.Column>
              </Grid.Row>
            ))
          : null}
        <Grid.Row textAlign="center" className="button-container">
          {skippableSectionBuilder(
            <Button onClick={backInternalStep} className="add-vehicle">
              + Add Another Vehicle
            </Button>
          )}
        </Grid.Row>
        <Divider fitted />
        <Grid.Row textAlign="center" className="button-container">
          <NextStepButton nextStep={nextStep} stepCompleted={stepCompleted} />
        </Grid.Row>
      </Grid>
    </Fragment>
  );
}

export default DeliveryVehiclesConfirmation;
