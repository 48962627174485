import 'semantic-ui-css/semantic.min.css';
import '../../styles/global.scss';

import React, { useContext } from 'react';

import { DemoModalContext } from '../layout/DemoModalProvider';
import { Link } from 'gatsby';
import Logo from './Logo';

const IntroHeader = () => {
  const { toggleOpen } = useContext(DemoModalContext);
  return (
    <header className="main-nav">
      <div className="innerWidth">
        <div className="ui grid">
          <div className="five wide column">
            <a href="/">
              <Logo />
            </a>
          </div>

          <nav className="eleven wide computer only column">
            <div className="ui menu">
              <div className="right menu">
                <Link to="/auth" className="item login">
                  Login
                </Link>
                <a href="https://getgrowflow.com/reviews/" className="item login">
                  Reviews
                </a>
                <a
                  href="#"
                  onClick={toggleOpen}
                  className="ui right labeled icon basic button demo GAds-demo"
                >
                  <i className="right arrow icon"></i>
                  Book Demo
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="wave-down"></div>
    </header>
  );
};

export default IntroHeader;
