import { Button, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import React from 'react';
const noOp = () => {};

export const createSubmitHandler = onSubmit => (values, { resetForm }) => {
  onSubmit(values);
  resetForm();
};

const actions = ({ cancelText, showCancel, onCancel, submitText, working }) => (
  <Form.Group widths="equal">
    <Button
      disabled={working}
      loading={working}
      size="large"
      type="submit"
      className="button-container add-employee"
    >
      {submitText}
    </Button>

    {showCancel && (
      <Button size="large" onClick={onCancel} className="button-container cancel">
        {cancelText}
      </Button>
    )}
  </Form.Group>
);

export const form = ({
  formInitHandler = noOp,
  cancelText,
  initialValues,
  showCancel = true,
  onCancel,
  onSubmit,
  renderFields,
  submitText,
  validator,
  enableReinitialize = true,
  noValidate = true,
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    onSubmit={createSubmitHandler(onSubmit)}
    validate={validator}
  >
    {formProps => {
      formInitHandler(formProps);
      return (
        <Form onSubmit={formProps.handleSubmit} noValidate={noValidate}>
          {renderFields(formProps)}

          {actions({
            cancelText,
            onCancel,
            showCancel,
            submitText,
            ...formProps,
          })}
        </Form>
      );
    }}
  </Formik>
);
