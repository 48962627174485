import { Button, Divider, Form, Grid, Modal } from 'semantic-ui-react';
import React, { useState } from 'react';
import { Formik } from 'formik';
import NextStepButton from '../widgets/NextStepButton';

function StrainsConfirmation({
  addedStrains,
  createDeleteStrainHandler,
  onSubmit,
  backInternalStep,
  nextStep,
  stepCompleted,
  skippableSectionBuilder,
  skippingStrains,
}) {
  const [strainIdToEdit, setStrainIdToEdit] = useState(false);

  const hideEditModal = () => setStrainIdToEdit('');
  const triggerStrainEdit = id => () => setStrainIdToEdit(id);

  return (
    <div className="strain-review-grid">
      <Grid columns={2}>
        {addedStrains.map(value => (
          <Grid.Row key={value.id}>
            <Grid.Column width={12}>
              <p>{value.name}</p>
            </Grid.Column>
            <Grid.Column width={2}>
              <Modal
                trigger={
                  <Button onClick={triggerStrainEdit(value.id)} fluid>
                    Edit
                  </Button>
                }
                open={strainIdToEdit === value.id}
              >
                <Modal.Header>Edit Strain "{value.name}"</Modal.Header>
                <Modal.Content>
                  <Formik
                    initialValues={value}
                    validate={values => validate(values)}
                    onSubmit={(values, { resetForm }) => {
                      onSubmit(values);
                      resetForm();
                      hideEditModal();
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit} noValidate>
                        <Grid columns={2}>
                          <Grid.Row>
                            <Grid.Column width={11}>
                              <Form.Input
                                fluid
                                label="New Name"
                                name="name"
                                value={values.name}
                                placeholder="Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.name && touched.name && errors.name}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Button positive type="submit">
                              Save
                            </Button>
                            <Button type="click" onClick={hideEditModal}>
                              Cancel
                            </Button>
                            <Button
                              negative
                              type="click"
                              onClick={createDeleteStrainHandler(value.id)}
                            >
                              Delete
                            </Button>
                          </Grid.Row>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Modal.Content>
              </Modal>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button negative type="click" onClick={createDeleteStrainHandler(value.id)}>
                Delete
              </Button>
            </Grid.Column>
          </Grid.Row>
        ))}
        <Grid.Row textAlign="center" className="button-container">
          {skippableSectionBuilder(
            <Button onClick={backInternalStep} className="add-vehicle">
              + Add Another Strain
            </Button>
          )}
        </Grid.Row>
        <Divider fitted fluid />
        <Grid.Row textAlign="center" className="button-container">
          <NextStepButton
            isSkippingStep={skippingStrains}
            stepCompleted={stepCompleted}
            nextStep={nextStep}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default StrainsConfirmation;
