import { SEO, WizardLayout } from 'components';

import { Link } from 'gatsby';
import React from 'react';

const SecondPage = () => (
  <WizardLayout>
    <SEO title="Page two" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </WizardLayout>
);

export default SecondPage;
