import { Container, Divider } from 'semantic-ui-react';

import LicenseItem from './LicenseItem';
import React from 'react';
import { getLicenses } from '../../utilities/licenses';

const LicenseDisplay = ({ confirmedLicenses = [], onClick }) => {
  const { licenses = [] } = getLicenses();
  const pendingLicenses = licenses.filter(
    license => !confirmedLicenses.includes(license)
  );

  return (
    <Container>
      Confirmed Licenses
      <Divider />
      {confirmedLicenses.map(license => (
        <LicenseItem
          key={license}
          title={license}
          onEdit={() => onClick(license)}
          pending={false}
        />
      ))}
      {pendingLicenses.length > 0 && (
        <>
          Pending Confirmation
          <Divider />
          {pendingLicenses.map(license => (
            <LicenseItem
              key={license}
              title={license}
              onEdit={() => onClick(license)}
              pending={true}
            />
          ))}
        </>
      )}
    </Container>
  );
};

export default LicenseDisplay;
