import 'semantic-ui-css/semantic.min.css';
import '../../styles/global.scss';

import { IntroFooter, IntroHeader, Page } from 'components';
import React, { Fragment } from 'react';

import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const IntroLayout = ({ children, centered, className, columns }) => (
  <Page className="bg-color-purple-dark">
    <IntroHeader />
    <div className="bg-color-light-gray intro-container">
      <Grid columns={columns}>
        {columns === 3 ? (
          <Fragment>
            <Grid.Column width={2} />
            <Grid.Column width={centered ? 12 : 10}>
              <main className={className}>{children}</main>
            </Grid.Column>
            <Grid.Column width={centered ? 2 : 4} />
          </Fragment>
        ) : null}
        {columns === 2 ? (
          <Fragment>
            <Grid.Column width={2} />
            <Grid.Column width={13}>
              <main className={className}>{children}</main>
            </Grid.Column>
          </Fragment>
        ) : null}
      </Grid>
    </div>
    <IntroFooter />
  </Page>
);

IntroLayout.defaultProps = {
  columns: 3,
};

IntroLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntroLayout;
