import React from 'react';

const BoxText = ({ children }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
    <div className="green-bar"></div>
    <div style={{ display: 'inline-block' }}>{children}</div>
  </div>
);

export default BoxText;
