import { Button, Grid, Modal } from 'semantic-ui-react';
import React, { Fragment, useState } from 'react';

import Calendly from './Calendly';

function DemoModal({ isOpen, toggleOpen }) {
  const [wholesaleDemo, setWholesaleDemo] = useState(false);
  const [retailDemo, setRetailDemo] = useState(false);
  const openWholesale = () => {
    setWholesaleDemo(true);
    setRetailDemo(false);
  };
  const openRetail = () => {
    setRetailDemo(true);
    setWholesaleDemo(false);
  };
  const back = () => {
    setRetailDemo(false);
    setWholesaleDemo(false);
  };
  return (
    <Modal open={isOpen} onClose={toggleOpen} closeIcon className="demo-modal">
      <h1>
        {wholesaleDemo
          ? 'GROWFLOW WHOLESALER DEMO'
          : retailDemo
          ? 'GROWFLOW POINT-OF-SALE DEMO'
          : 'BOOK A ONE-ON-ONE DEMO'}
      </h1>
      <div className="modal-content">
        {!wholesaleDemo && !retailDemo ? (
          <Fragment>
            <h2>Choose the demo that is right for you.</h2>
            <div className="box-container">
              <div className="text-container">
                <p>
                  GrowFlow can help all license types and offers two primary solutions.
                </p>
                <p>One for Point-of-Sale and managing Retail locations.</p>
                <p>
                  And another software solution for all activities before product reaches
                  the store.
                </p>
              </div>
            </div>
            <div className="columns-container">
              <Grid columns={2}>
                <Grid.Column width={8}>
                  <h3>Wholesaler Demo</h3>
                  <ul>
                    <li>
                      <i className="check circle icon"></i>Nurseries / Producers /
                      Cultivators
                    </li>
                    <li>
                      <i className="check circle icon"></i>Processors
                    </li>
                    <li>
                      <i className="check circle icon"></i>Manufacturers /Distributors
                    </li>
                  </ul>

                  <Button onClick={openWholesale}>
                    <p>Wholesale Demo</p>
                    <div className="icon-container">
                      <i className="right arrow icon"></i>
                    </div>
                  </Button>
                </Grid.Column>
                <Grid.Column width={8}>
                  <h3>Retailer Demo</h3>
                  <ul>
                    <li>
                      <i className="check circle icon"></i>Retailers
                    </li>
                    <li>
                      <i className="check circle icon"></i>Dispensaries
                    </li>
                    <li>
                      <i className="check circle icon"></i>Complete End-to-End Businesses
                    </li>
                  </ul>
                  <Button onClick={openRetail}>
                    <p>Point-of-Sale Demo</p>
                    <div className="icon-container">
                      <i className="right arrow icon"></i>
                    </div>
                  </Button>
                </Grid.Column>
              </Grid>
            </div>
          </Fragment>
        ) : null}
        {wholesaleDemo || retailDemo ? (
          <a href="#" onClick={back} className="back-link">
            <i className="angle left icon"></i> Back
          </a>
        ) : null}
        {wholesaleDemo ? (
          <Calendly calendlyUrl="https://calendly.com/growflow-sales/growflow-grow-demo" />
        ) : null}
        {retailDemo ? (
          <Calendly calendlyUrl="https://calendly.com/growflow-sales/growflow-retail-demo" />
        ) : null}
      </div>
    </Modal>
  );
}

export default DemoModal;
