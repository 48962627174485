import './Item.scss';
import { Button, Icon } from 'semantic-ui-react';
import React from 'react';

const Item = ({ id, title, onEdit }) => (
  <div key={title} className="Item">
    <Button onClick={() => onEdit(id)}>
      <Icon name="edit" />
      <span>{title}</span>
    </Button>
  </div>
);

export default Item;
