import { Button, Divider, Grid } from 'semantic-ui-react';
import NextStepButton from '../widgets/NextStepButton';
import React from 'react';

function InventoryConfirmation({
  setFormStep,
  backInternalStep,
  nextStep,
  introInformation,
  stepCompleted,
}) {
  return (
    <div>
      <h3>Inventory answers for {introInformation.companyName}</h3>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={12}>
            Products sold to other Producers or Processors
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              fluid
              onClick={() => {
                setFormStep(1);
                backInternalStep();
              }}
            >
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider fitted />
        <Grid.Row>
          <Grid.Column width={12}>Products sold to Retailers or Dispensaries</Grid.Column>
          <Grid.Column width={4}>
            <Button
              fluid
              onClick={() => {
                setFormStep(2);
                backInternalStep();
              }}
            >
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider fitted />
        <Grid.Row textAlign="center" className="button-container">
          <NextStepButton nextStep={nextStep} stepCompleted={stepCompleted} />
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default InventoryConfirmation;
