import './Input.scss';
import { Form } from 'semantic-ui-react';
import React from 'react';

export const Input = ({
  label,
  required,
  name,
  value,
  fluid,
  placeholder,
  onChange,
  onBlur,
  error,
}) => (
  <Form.Input
    className="Input"
    fluid={fluid !== undefined ? fluid : true}
    label={label || null}
    required={required || false}
    name={name}
    value={value}
    placeholder={placeholder || ''}
    onChange={onChange}
    onBlur={onBlur}
    error={error}
  />
);

/**
 * An input that is automatically attached to formik props,
 * only specifying props unique to this input (label and field).
 */
export const FormikInput = props => {
  const {
    // props unique to the input
    label,
    field,

    // formik props (use {...props} within Formik)
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  } = props;

  return (
    <Input
      {...props}
      label={label}
      value={values[field]}
      error={errors && (!touched || touched[field]) && errors[field]}
      name={field}
      onChange={event => {
        handleChange(event);
      }}
      onBlur={handleBlur}
    />
  );
};

export default Input;
