import { Modal } from 'semantic-ui-react';
import { PrinterAndLabelFields } from './PrinterAndLabelFields';
import React from 'react';
import { form } from '../../utilities/forms';
import { validatePrinter } from '../../utilities/printersAndLabels';

const noOp = () => {};

export const PrinterAndLabelForm = ({
  cancelText,
  fieldValues,
  onCancel,
  onSubmit,
  registerForm = noOp,
  submitText,
  showCancel = false,
  validator,
  includeActions = true,
  id,
}) =>
  form({
    formInitHandler: registerForm,
    includeActions,
    enableReinitialize: false,
    registerForm,
    cancelText,
    initialValues: fieldValues,
    onCancel,
    onSubmit,
    showCancel,
    renderFields: fieldProps => <PrinterAndLabelFields id={id} {...fieldProps} />,
    submitText,
    validator,
  });

export const ModalPrinterAndLabelForm = ({ onClose, printer, type, saveHandler }) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>{type} Printer & Labels</h2>
    </Modal.Header>
    <Modal.Content>
      <PrinterAndLabelForm
        validator={validatePrinter}
        submitText="Save & Close"
        cancelText="Cancel Changes"
        onSubmit={saveHandler}
        showCancel={true}
        onCancel={onClose}
        fieldValues={printer}
      />
    </Modal.Content>
  </Modal>
);
