import { Button, Divider, Grid } from 'semantic-ui-react';
import React, { useState } from 'react';

import NextStepButton from '../widgets/NextStepButton';
import YesNoModal from '../widgets/YesNoModal';

const employeeFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

const EmployeeInformationConfirmation = ({
  initialValues,
  editEmployee,
  deleteEmployee,
  introInformation,
  backInternalStep,
  nextStep,
  stepCompleted,
  skippingEmployees,
  skippableSectionBuilder,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const deleteAnEmployee = ({ id }) => {
    setDeleteModalOpen(false);
    setEmployeeIdToDelete(null);
    deleteEmployee(id);
  };

  const triggerDelete = ({ id }) => {
    setEmployeeIdToDelete(id);
    setDeleteModalOpen(true);
  };

  const deletionModal = employee => {
    const isOpen = deleteModalOpen && employeeIdToDelete === employee.id;

    return (
      <YesNoModal
        isOpen={isOpen}
        title={`Delete Employee: ${employeeFullName(employee)}`}
        onYes={() => deleteAnEmployee(employee)}
        onNo={() => setDeleteModalOpen(false)}
      />
    );
  };

  return (
    <div>
      <h2>Employees/Owners for {introInformation.companyName}</h2>
      <Grid columns={2}>
        {initialValues
          ? Object.entries(initialValues).map(([key, employee]) => (
              <Grid.Row key={key}>
                <Grid.Column width={8}>
                  <p>{employeeFullName(employee)}</p>
                </Grid.Column>
                {employee.shouldAddAsUserOnYourGrowFlowAccount && (
                  <Grid.Column width={4}>
                    <h3>GrowFlow User</h3>
                  </Grid.Column>
                )}
                <Grid.Column width={2}>
                  <Button
                    onClick={() => editEmployee(employee.id, backInternalStep)}
                    fluid
                  >
                    Edit
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button negative type="click" onClick={() => triggerDelete(employee)}>
                    Delete
                  </Button>
                </Grid.Column>
                {deletionModal(employee)}
              </Grid.Row>
            ))
          : null}
        <Grid.Row textAlign="center" className="button-container">
          {skippableSectionBuilder(
            <Button onClick={backInternalStep} className="add-vehicle">
              + Add Another Employee/Owner
            </Button>
          )}
        </Grid.Row>
        <Divider fitted fluid />
        <Grid.Row textAlign="center" className="button-container">
          <NextStepButton
            isSkippingStep={skippingEmployees}
            stepCompleted={stepCompleted}
            nextStep={nextStep}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default EmployeeInformationConfirmation;
