import { Button, Grid } from 'semantic-ui-react';
import React, { useState } from 'react';
import {
  allState as allPrintersAndLabelsState,
  createQueriesHook as createPrinterAndLabelQueries,
  createSavePrinterHandler,
  validatePrinter,
} from '../../utilities/printersAndLabels';
import { ModalPrinterAndLabelForm } from 'components/Forms/PrinterAndLabelForms';
import NextStepButton from '../widgets/NextStepButton';
import { validId } from '../../utilities/matchers';

const nameTemplate = ({ manufacturer, modelNumber, labelHeight, labelWidth }) => (
  <span>
    {manufacturer} {modelNumber}, {labelHeight}" x {labelWidth}" Labels, Style
  </span>
);

const noPrinterTemplate = () => <span>Have not selected Printer Yet</span>;

const printerDisplayName = printer =>
  printer.noPrinterSelected ? noPrinterTemplate() : nameTemplate(printer);

const PrintersConfirmation = ({ nextStep, stepCompleted }) => {
  const [printerIdBeingEdited, setPrinterIdBeingEdited] = useState('');
  const { printersAndLabelsState } = allPrintersAndLabelsState();

  const printerAndLabelQueries = createPrinterAndLabelQueries({
    printersAndLabelsState,
  });

  const { printers } = printerAndLabelQueries;

  const onModalClose = () => {
    setPrinterIdBeingEdited('');
  };

  const printerSaveHandler = createSavePrinterHandler({
    printersAndLabelsState,
    completion: onModalClose,
  });

  const editPrinter = id => {
    setPrinterIdBeingEdited(id);
  };

  const printerEditModal = () =>
    validId(printerIdBeingEdited) ? (
      <ModalPrinterAndLabelForm
        printer={printerAndLabelQueries.printerDetails[printerIdBeingEdited]}
        validator={validatePrinter}
        saveHandler={printerSaveHandler}
        onClose={onModalClose}
      />
    ) : null;

  const printerSection = printer => (
    <Grid.Row key={printer.id}>
      <Grid.Column width={12}>{printerDisplayName(printer)}</Grid.Column>
      <Grid.Column width={4}>
        <Button onClick={() => editPrinter(printer.id)}>Edit</Button>
      </Grid.Column>
    </Grid.Row>
  );

  return (
    <div>
      <h3>Printers & Labels for McTesterson Inc.</h3>

      {printerEditModal()}

      <Grid columns={2}>
        {printers.map(printerSection)}

        <Grid.Row textAlign="center" className="button-container">
          <NextStepButton nextStep={nextStep} stepCompleted={stepCompleted} />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default PrintersConfirmation;
