import { Form, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import { requiresHarvestDate, requiresWetWeight } from '../../utilities/plants';
import DateInput from '../widgets/DateInput';
import Input from '../widgets/Input';

const createDateChangeHandler = (fieldName, setFieldValue) => (e, { value }) => {
  setFieldValue(fieldName, value === null ? '' : value);
};

const strainOptions = values =>
  values.map(val => ({
    id: val.id,
    text: val.name,
    value: val.id,
  }));

const stageOptions = values => values;

const changeStrain = setFieldValue => (e, { value }) => {
  setFieldValue('strainId', value);
};

export const PlantFields = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  strains,
  stages,
}) => {
  const dateOfBirth = createDateChangeHandler('dateOfBirth', setFieldValue);
  const harvestDate = createDateChangeHandler('harvestDate', setFieldValue);

  const changeStage = setFieldValue => (e, { value }) => {
    setFieldValue('stage', value);
  };

  return (
    <Fragment>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Form.Group widths="equal">
              <DateInput
                fluid
                required
                label="Date of Birth"
                name="dateOfBirth"
                value={values.dateOfBirth}
                placeholder="Date Of Birth"
                onChange={dateOfBirth}
                onBlur={handleBlur}
                error={errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
              />
              <Form.Dropdown
                className="Input"
                error={errors.strainId && touched.strainId && errors.strainId}
                clearable
                search
                selection
                fluid
                id="strainId"
                name="strainId"
                label="Strain"
                onBlur={handleBlur}
                onChange={changeStrain(setFieldValue)}
                options={strainOptions(strains)}
                placeholder="Strain"
                value={values.strainId}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Group widths="equal">
              <Input
                fluid
                label="Plant Count"
                name="plantCount"
                value={values.plantCount}
                placeholder="Plant Count..."
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.plantCount && touched.plantCount && errors.plantCount}
              />
              <Form.Dropdown
                className="Input"
                error={errors.stage && touched.stage && errors.stage}
                search
                selection
                fluid
                id="stage"
                name="stage"
                label="Growing Stage"
                onBlur={handleBlur}
                onChange={changeStage(setFieldValue)}
                options={stageOptions(stages)}
                placeholder="Choose a growing stage"
                value={values.stage}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Group widths="equal">
              {requiresHarvestDate(values.stage) && (
                <DateInput
                  fluid
                  required
                  label="Harvest Date"
                  name="harvestDate"
                  value={values.harvestDate}
                  placeholder="Harvest Date"
                  onChange={harvestDate}
                  onBlur={handleBlur}
                  error={errors.harvestDate && touched.harvestDate && errors.harvestDate}
                />
              )}
              {requiresWetWeight(values.stage) && (
                <Input
                  fluid
                  label="Wet Weight Total (g)"
                  name="wetWeight"
                  value={values.wetWeight}
                  placeholder="Wet Weight..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.wetWeight && touched.wetWeight && errors.wetWeight}
                />
              )}
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};
