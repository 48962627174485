import { DemoModalContext, DemoModalProvider } from './DemoModalProvider';
import React, { Fragment, useContext } from 'react';
import { Container } from 'semantic-ui-react';
import DemoModal from '../widgets/DemoModal';

const styles = {
  page: {
    width: '1366px',
  },
  background: {
    width: '100%',
    minHeight: '100vh',
  },
};

const Page = ({ className = 'bg-color-light-gray', children }) => {
  const { isOpen, toggleOpen } = useContext(DemoModalContext);
  return (
    <Fragment>
      <Container className={className} style={styles.background}>
        <Container style={styles.page}>
          <div style={styles.container}>{children}</div>
        </Container>
      </Container>
      <DemoModal isOpen={isOpen} toggleOpen={toggleOpen} />
    </Fragment>
  );
};

export default props => (
  <DemoModalProvider>
    <Page {...props} />
  </DemoModalProvider>
);
