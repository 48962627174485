import { Divider, Form, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import { Formik } from 'formik';

const ppconfig = [
  {
    id: 'seeds',
    title: 'Seeds',
    description: 'Sold by quantity',
  },
  {
    id: 'clones',
    title: 'Clones',
    description: 'A Clone is defined as',
  },
  {
    id: 'veggingPlants',
    title: 'Vegging Plants',
    description:
      'Plants that are in the vegetative stage and have not begun flowering yet.',
  },
  {
    id: 'wetHarvestedFlower',
    title: 'Wet Harvested Flower',
    description:
      'Typically called ‘Wet Flower’ this is bud that was harvest, but not yet dried and cured	',
  },
  {
    id: 'driedAndCuredHarvestFlower',
    title: 'Dried and Cured Harvest Flower',
    description:
      'This is classic ‘bud’ that is all cured up and trimmed - ready for sale',
  },
  {
    id: 'driedTrimAndShake',
    title: 'Dried Trim or Shake',
    description:
      'The good ‘sugar leaf’ that is collected and saved during harvest, that is not ‘bud’',
  },
  {
    id: 'kief',
    title: 'Kief',
    description:
      'The collected, tiny crystals screened from the harvest and curing process.',
  },
  {
    id: 'bhoCO2OilOrOtherExtract',
    title: 'BHO, CO2 Oil or other Extract',
    description:
      'Product created by extracting THC from the flower using a chemical solvent',
  },
  {
    id: 'rosinHashOrOtherConcentrates',
    title: 'Rosin, Hash or other Concentrates',
    description:
      'Product created from concentrating THC from the flower, WITHOUT a chemical solvent',
  },
  {
    id: 'tinctures',
    title: 'Tincture',
    description: 'A product created in an alcohol solvent.',
  },
  {
    id: 'edibles',
    title: 'Edibles',
    description:
      'Typically cookies, brownies, candies or sodas. Edible products infused with THC.',
  },
];

const rpconfig = [
  {
    id: 'flowerAkaBud',
    title: 'Flower aka ‘Bud’',
    description:
      "The classic bud. Can be sold to retailers prepackaged or 'Deli Style' in large amounts.",
  },
  {
    id: 'preRollJointsFromFlower',
    title: 'Pre-Roll Joints from Flower',
    description: ' A Joint made from just pure flower.',
  },
  {
    id: 'preRollJointsFromShakeOrTrim',
    title: 'Pre-Roll Joints from Shake or Trim',
    description:
      'Joints that are made from your shake and trim gathered during harvest, exclusively.',
  },
  {
    id: 'infusedProducts',
    title: 'Infused Products',
    description:
      'Products that combine two things. Example - a flower joint dipped in hash oil.',
  },
  {
    id: 'vapeCartridgesOrOtherExtracts',
    title: 'Vape Cartridges or Other Extracts',
    description: 'Anything that is created using a solvent for extraction.',
  },
  {
    id: 'hashRosinOrOtherConcentrates',
    title: 'Hash, Rosin or other Concentrate',
    description: 'Any concentrate that is created without the use of chemical solvents.',
  },
  {
    id: 'thcCapsulesOrOtherCapsules',
    title: 'THC Capsules or other Capsules',
    description: 'Pills that are eash to ingest.',
  },
  {
    id: 'edibles',
    title: 'Edibles',
    description: 'Typcial examples are cookies, brownies, candies and even sodas.',
  },
  {
    id: 'tinctures',
    title: 'Tinctures',
    description: 'Usually in a dropper form. Created by using alcohol as a solvent.',
  },
  {
    id: 'balmsSalvesOrLotions',
    title: 'Balms, Salves or Lotions',
    description: 'Any product containing THC that is not ingested or smoked.',
  },
  {
    id: 'thcPatches',
    title: 'THC Patches',
    description: 'These are transdermal patches that get attached to the user’s skin.',
  },
  {
    id: 'thcSuppositories',
    title: 'THC Suppositories',
    description: 'The less-thought-of form of ingestion.',
  },
];

function configurefields(config, values) {
  return Object.keys(values).map(key => ({
    key,
    ...config.find(({ id }) => id === key),
  }));
}

const handleRadioChange = (e, { name, value }, setFieldValue) => {
  setFieldValue(name, value);
};

function InventoryForm({
  initialValues,
  working,
  onSubmit,
  nextInternalStep,
  formStep,
  setFormStep,
}) {
  const fieldValues = {
    pp: {
      seeds: (initialValues && initialValues.pp && initialValues.pp.seeds) || '',
      clones: (initialValues && initialValues.pp && initialValues.pp.clones) || '',
      veggingPlants:
        (initialValues && initialValues.pp && initialValues.pp.veggingPlants) || '',
      wetHarvestedFlower:
        (initialValues && initialValues.pp && initialValues.pp.wetHarvestedFlower) || '',
      driedAndCuredHarvestFlower:
        (initialValues &&
          initialValues.pp &&
          initialValues.pp.driedAndCuredHarvestFlower) ||
        '',
      driedTrimAndShake:
        (initialValues && initialValues.pp && initialValues.pp.driedTrimAndShake) || '',
      kief: (initialValues && initialValues.pp && initialValues.pp.kief) || '',
      bhoCO2OilOrOtherExtract:
        (initialValues && initialValues.pp && initialValues.pp.bhoCO2OilOrOtherExtract) ||
        '',
      rosinHashOrOtherConcentrates:
        (initialValues &&
          initialValues.pp &&
          initialValues.pp.rosinHashOrOtherConcentrates) ||
        '',
      tinctures: (initialValues && initialValues.pp && initialValues.pp.tinctures) || '',
      edibles: (initialValues && initialValues.pp && initialValues.pp.edibles) || '',
    },
    rp: {
      flowerAkaBud:
        (initialValues && initialValues.rp && initialValues.rp.flowerAkaBud) || '',
      preRollJointsFromFlower:
        (initialValues && initialValues.rp && initialValues.rp.preRollJointsFromFlower) ||
        '',
      preRollJointsFromShakeOrTrim:
        (initialValues &&
          initialValues.rp &&
          initialValues.rp.preRollJointsFromShakeOrTrim) ||
        '',
      infusedProducts:
        (initialValues && initialValues.rp && initialValues.rp.infusedProducts) || '',
      vapeCartridgesOrOtherExtracts:
        (initialValues &&
          initialValues.rp &&
          initialValues.rp.vapeCartridgesOrOtherExtracts) ||
        '',
      hashRosinOrOtherConcentrates:
        (initialValues &&
          initialValues.rp &&
          initialValues.rp.hashRosinOrOtherConcentrates) ||
        '',
      thcCapsulesOrOtherCapsules:
        (initialValues &&
          initialValues.rp &&
          initialValues.rp.thcCapsulesOrOtherCapsules) ||
        '',
      edibles: (initialValues && initialValues.rp && initialValues.rp.edibles) || '',
      tinctures: (initialValues && initialValues.rp && initialValues.rp.tinctures) || '',
      balmsSalvesOrLotions:
        (initialValues && initialValues.rp && initialValues.rp.balmsSalvesOrLotions) ||
        '',
      thcPatches:
        (initialValues && initialValues.rp && initialValues.rp.thcPatches) || '',
      thcSuppositories:
        (initialValues && initialValues.rp && initialValues.rp.thcSuppositories) || '',
    },
    id: (initialValues && initialValues.id) || '',
  };

  return (
    <Fragment>
      <h3>Understanding Inventory in Traceability.</h3>
      <p>
        Next we will ask you some questions about your inventory. In traceability, there
        are generally two ‘flavors’ of inventory - products you sell to other producers,
        processors, manufacturers &amp; distributors etc. and products you sell to
        Retailers or Dispensaries.
      </p>
      <p>
        Don’t worry about the details too much at this point, our friendly GrowFlow staff
        will help you create your product catalog for both inventory types in a 1 on 1
        call.
      </p>
      <p>For now, just answer the questions below to the best of your ability.</p>
      <h3>
        <strong>
          {formStep === 1
            ? 'Inventory you sell or plan to sell to other Producers and Processors'
            : 'Inventory you sell or plan to sell to Retailers or Dispensaries'}
        </strong>
      </h3>
      <p>
        <i>
          {formStep === 1
            ? 'Answer all of the following questions about products you sell or plan to sell to other Producers and Processors.'
            : 'Answer all of the following questions about products you sell or plan to sell to Retailers or Dispensaries.'}
        </i>
      </p>
      <Formik
        initialValues={fieldValues}
        validate={values => validate(values)}
        onSubmit={values => {
          onSubmit(values);
          nextInternalStep();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Grid columns={2}>
              {formStep === 1 ? (
                <Fragment>
                  {values &&
                    values.pp &&
                    configurefields(ppconfig, values.pp).map(obj => (
                      <Fragment key={obj.id}>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <h3>{obj.title}</h3>
                            <p>{obj.description}</p>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Form.Radio
                              label="I currently sell this, and have some in stock"
                              name={`pp.${obj.key}`}
                              value="instock"
                              checked={
                                values && values.pp && values.pp[obj.key] === 'instock'
                              }
                              onChange={(e, selectedData) => {
                                handleRadioChange(e, selectedData, setFieldValue);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors &&
                                errors.pp &&
                                errors.pp[obj.key] &&
                                touched &&
                                touched.pp &&
                                touched.pp[obj.key]
                              }
                            />
                            <Form.Radio
                              label="I currently sell this, but I am out of stock right now"
                              name={`pp.${obj.key}`}
                              value="outofstock"
                              checked={
                                values && values.pp && values.pp[obj.key] === 'outofstock'
                              }
                              onChange={(e, selectedData) => {
                                handleRadioChange(e, selectedData, setFieldValue);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors &&
                                errors.pp &&
                                errors.pp[obj.key] &&
                                touched &&
                                touched.pp &&
                                touched.pp[obj.key]
                              }
                            />
                            <Form.Radio
                              label="I don’t sell this yet, but plan to in the future"
                              name={`pp.${obj.key}`}
                              value="future"
                              checked={
                                values && values.pp && values.pp[obj.key] === 'future'
                              }
                              onChange={(e, selectedData) => {
                                handleRadioChange(e, selectedData, setFieldValue);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors &&
                                errors.pp &&
                                errors.pp[obj.key] &&
                                touched &&
                                touched.pp &&
                                touched.pp[obj.key]
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Divider />
                      </Fragment>
                    ))}
                </Fragment>
              ) : null}
              {formStep === 2 ? (
                <Fragment>
                  {values &&
                    values.rp &&
                    configurefields(rpconfig, values.rp).map(obj => (
                      <Fragment key={obj.id}>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <h3>{obj.title}</h3>
                            <p>{obj.description}</p>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Form.Radio
                              label="I currently sell this, and have some in stock"
                              name={`rp.${obj.key}`}
                              value="instock"
                              checked={
                                values && values.rp && values.rp[obj.key] === 'instock'
                              }
                              onChange={(e, selectedData) => {
                                handleRadioChange(e, selectedData, setFieldValue);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors &&
                                errors.rp &&
                                errors.rp[obj.key] &&
                                touched &&
                                touched.rp &&
                                touched.rp[obj.key]
                              }
                            />
                            <Form.Radio
                              label="I currently sell this, but I am out of stock right now"
                              name={`rp.${obj.key}`}
                              value="outofstock"
                              checked={
                                values && values.rp && values.rp[obj.key] === 'outofstock'
                              }
                              onChange={(e, selectedData) => {
                                handleRadioChange(e, selectedData, setFieldValue);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors &&
                                errors.rp &&
                                errors.rp[obj.key] &&
                                touched &&
                                touched.rp &&
                                touched.rp[obj.key]
                              }
                            />
                            <Form.Radio
                              label="I don’t sell this yet, but plan to in the future"
                              name={`rp.${obj.key}`}
                              value="future"
                              checked={
                                values && values.rp && values.rp[obj.key] === 'future'
                              }
                              onChange={(e, selectedData) => {
                                handleRadioChange(e, selectedData, setFieldValue);
                              }}
                              onBlur={handleBlur}
                              error={
                                errors &&
                                errors.rp &&
                                errors.rp[obj.key] &&
                                touched &&
                                touched.rp &&
                                touched.rp[obj.key]
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Divider />
                      </Fragment>
                    ))}
                </Fragment>
              ) : null}
              <Form.Input type="hidden" name="id" value={values && values.id} />
              <Grid.Row>
                {formStep === 1 ? (
                  <Form.Button
                    size="large"
                    className="button-container"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      setFormStep(2);
                    }}
                  >
                    Continue To Retail Products
                  </Form.Button>
                ) : null}
                {formStep === 2 ? (
                  <Form.Button
                    disabled={working}
                    loading={working}
                    size="large"
                    type="submit"
                    className="button-container"
                  >
                    Continue To Review Answers
                  </Form.Button>
                ) : null}
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}

export default InventoryForm;
