import "./Button.scss";
import { Icon, Button as SemanticButton } from "semantic-ui-react";
import React from "react";

export const Button = ({
  children,
  onClick,
  text,
  icon,
  className,
  negative,
  primary,
  disabled,
  loading,
  type,
  inline
}) => {
  const fluid = !inline || true;
  return (
    <SemanticButton
      className={`Button ${className || ""} ${!fluid ? "inline" : "full"}`}
      onClick={onClick}
      negative={!!negative}
      positive={!!primary}
      disabled={disabled}
      type={type === undefined ? "button" : type}
      loading={loading}
      fluid={fluid}
    >
      {icon && <Icon name={icon} />}
      {text || null}
      {children || null}
    </SemanticButton>
  );
};

export default Button;
