import './StateSelect.scss';
import { Form } from 'semantic-ui-react';
import React from 'react';
import { StateOptions } from '../../data/StateOptions';

const createChangeHandler = changeHandler => (e, { name, value }) => {
  changeHandler(name, value);
};

const StateSelect = ({
  id,
  label,
  required,
  name,
  value,
  fluid,
  options,
  placeholder,
  onChange,
  onBlur,
  error,
}) => (
  <Form.Select
    className="Input"
    error={error}
    fluid={!!fluid}
    id={id}
    label={label || null}
    name={name}
    onBlur={onBlur}
    onChange={createChangeHandler(onChange)}
    options={options || StateOptions.map(({ value, text }) => ({ value, text }))}
    placeholder={placeholder || ''}
    required={!!required}
    value={value}
  />
);

export default StateSelect;
