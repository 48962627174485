import { Button, Modal } from 'semantic-ui-react';
import { InventoryRoomFields, PlantRoomFields } from './RoomFields';
import React, { Fragment } from 'react';
import {
  newInventoryRoom,
  newPlantRoom,
  validateInventoryRoom,
  validatePlantRoom,
} from '../../utilities/rooms';

import { form } from '../../utilities/forms';

export const PlantRoomForm = ({
  cancelText,
  fieldValues,
  onCancel,
  onSubmit,
  showCancel = true,
  purposes,
  submitText,
  validator,
}) =>
  form({
    cancelText,
    initialValues: fieldValues,
    onCancel,
    onSubmit,
    showCancel,
    renderFields: fieldProps => <PlantRoomFields {...fieldProps} purposes={purposes} />,
    submitText,
    validator,
  });

export const InventoryRoomForm = ({
  cancelText,
  fieldValues,
  onCancel,
  onSubmit,
  showCancel = true,
  submitText,
  validator,
}) =>
  form({
    cancelText,
    initialValues: fieldValues,
    onCancel,
    onSubmit,
    showCancel,
    renderFields: fieldProps => <InventoryRoomFields {...fieldProps} />,
    submitText,
    validator,
  });

export const ModalEditInventoryRoomForm = ({
  deleteHandler,
  onClose,
  room,
  saveHandler,
}) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>Edit Inventory Room "{name}"</h2>
      <h3>On License # {room.license} </h3>
    </Modal.Header>
    <Modal.Content>
      <InventoryRoomForm
        license={room.license}
        validator={validateInventoryRoom}
        submitText="Save"
        cancelText="Cancel"
        onSubmit={saveHandler}
        onCancel={onClose}
        fieldValues={room}
      />
      <Button onClick={deleteHandler}>Delete</Button>
    </Modal.Content>
  </Modal>
);

export const ModalAddInventoryRoomForm = ({ license, saveHandler, onClose }) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>Add Inventory Room</h2>
      <h3>On License # {license} </h3>
    </Modal.Header>
    <Modal.Content>
      <InventoryRoomForm
        license={license}
        validator={validateInventoryRoom}
        submitText="Save"
        cancelText="Cancel"
        onSubmit={saveHandler}
        onCancel={onClose}
        fieldValues={{ ...newInventoryRoom(), license }}
      />
    </Modal.Content>
  </Modal>
);

export const ModalEditPlantRoomForm = ({
  deleteHandler,
  onClose,
  purposes,
  room,
  saveHandler,
}) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>Edit Plant Room "{room.name}"</h2>
      <h3>On License # {room.license}</h3>
    </Modal.Header>
    <Modal.Content>
      <Fragment>
        <PlantRoomForm
          license={room.license}
          validator={validatePlantRoom}
          submitText="Save"
          cancelText="Cancel"
          onSubmit={saveHandler}
          onCancel={onClose}
          purposes={purposes}
          enableReinitialize={false}
          fieldValues={{ ...room }}
        />
        <Button onClick={deleteHandler}>Delete</Button>
      </Fragment>
    </Modal.Content>
  </Modal>
);

export const ModalAddPlantRoomForm = ({ license, onClose, purposes, saveHandler }) => (
  <Modal open={true} closeOnDimmerClick={true} onClose={onClose}>
    <Modal.Header>
      <h2>Add Plant Room</h2>
      <h3>On License # {license}</h3>
    </Modal.Header>
    <Modal.Content>
      <Fragment>
        <PlantRoomForm
          license={license}
          validator={validatePlantRoom}
          submitText="Save"
          cancelText="Cancel"
          onSubmit={saveHandler}
          onCancel={onClose}
          purposes={purposes}
          enableReinitialize={false}
          fieldValues={{ ...newPlantRoom(), license }}
        />
      </Fragment>
    </Modal.Content>
  </Modal>
);
