import { Icon, Message } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import { PageIntroduction } from 'components';
import { useIntroduction } from '../../utilities/hooks';

const PageWithIntro = ({ step, children }) => {
  const [shouldShowIntro, hideIntro] = useIntroduction({ step });

  const view = shouldShowIntro ? (
    <PageIntroduction onSubmit={hideIntro} step={step} />
  ) : (
    children
  );

  const mobileWarningSection = (
    <div className="mobile-only-container">
      <Message icon warning>
        <Icon name="exclamation" />
        <Message.Content>
          <Message.Header>The wizard is not optimized for mobile usage</Message.Header>
          <p>Please complete the wizard using a desktop computer</p>
        </Message.Content>
      </Message>
    </div>
  );

  return (
    <Fragment>
      {mobileWarningSection}
      {view}
    </Fragment>
  );
};

export default PageWithIntro;
