export * from '../../functions/users';
import Axios from 'axios';
import { getUser } from './auth';
import { createAndMatch as where } from './criteria';

const successfulStatusChange = where({
  ok: true,
});

export const changeVerifiedStatus = async ({ email, verified }) => {
  const user = getUser();
  if (!user) return false;

  const requestBody = {
    idToken: user.idToken,
    email,
    verified,
  };

  const response = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
    operationName: null,
    variables: {
      details: requestBody,
    },
    query: `mutation($details: ChangeUserVerifiedInput) {
        changeUserVerified(details: $details)
      }`,
  });

  const data = JSON.parse(response.data.data.changeUserVerified);

  return successfulStatusChange(data);
};
