import {
  allState as allStepStatusState,
  createQueries as createStepStatusQueries,
} from './stepStatus';
import { getStepEventTriggers, viewedPage } from '../events';

import { resumeStep } from './stepDefinitions';
import { useEffect } from 'react';

export const useIntroduction = ({ step: { eventName } }) => {
  const { stepStatusState } = allStepStatusState();
  const stepStatusQueries = createStepStatusQueries({ stepStatusState });
  const stepTriggers = getStepEventTriggers({ eventName, stepStatusState });

  const shouldShowIntroduction =
    eventName !== resumeStep.eventName && !stepStatusQueries.hasStarted(eventName);

  const hideIntro = () => {
    stepTriggers.started();
  };

  return [shouldShowIntroduction, hideIntro];
};

export const useTracking = eventName => {
  useEffect(() => {
    viewedPage(eventName);
  }, []);
};
