import './PlantsList.scss';
import { Container, Divider } from 'semantic-ui-react';
import PlantItem from './PlantItem';
import React from 'react';

const plantTitle = plant => `${plant.strain}, ${plant.dateOfBirth}`;

const PlantsList = ({ title, plants, onEdit }) => (
  <Container className="PlantsList">
    {title}
    <Divider />
    {plants.map(plant => (
      <PlantItem key={plant.id} id={plant.id} title={plantTitle(plant)} onEdit={onEdit} />
    ))}
  </Container>
);

export default PlantsList;
