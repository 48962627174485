import React, { useContext } from 'react';

import { DemoModalContext } from '../layout/DemoModalProvider';
import Logo from './Logo';
import VersionNumber from 'components/widgets/VersionNumber';

const IntroFooter = () => {
  const { toggleOpen } = useContext(DemoModalContext);
  return (
    <footer>
      <div className="wave-up"></div>
      <div className="ui mobile reversed stackable grid container">
        <div className="eight wide tablet four wide computer column">
          <h4>Solutions</h4>
          <ul>
            <li>
              <a href="https://getgrowflow.com/pricing/">Pricing</a>
            </li>
            <li>By area of supply chain</li>
            <li>
              <a href="https://getgrowflow.com/cultivators/">Grow Management</a>
            </li>
            <li>
              <a href="https://getgrowflow.com/processors/">Inventory Management</a>
            </li>
            <li>
              <a href="https://getgrowflow.com/point-of-sale/">Point-of-Sale</a>
            </li>
            <li>For Everyone</li>
            <li>
              <a href="https://getgrowflow.com/sales-order-process/">Sales Process</a>
            </li>
            <li>
              <a href="https://getgrowflow.com/storefront/">StoreFront</a>
            </li>
          </ul>
        </div>
        <div className="six wide tablet three wide computer column">
          <h4>GrowFlow</h4>
          <ul>
            {/* <li>
              <a href="about">About</a>
            </li> */}
            <li>
              <a href="https://getgrowflow.com/contact/">Contact</a>
            </li>
            {/* <li>
              <a href="careers">Careers</a>
            </li>
            <li>
              <a href="customers">Customers</a>
            </li>
            <li>
              <a href="press">Press</a>
            </li> */}
          </ul>
        </div>
        <div className="eight wide tablet four wide computer column">
          {/* <h4>Resources</h4>
          <ul>
            <li>
              <a href="blog">Blog</a>
            </li>
            <li>
              <a href="faqs">FAQ's</a>
            </li>
            <li>
              <a href="hardware">Hardware / Tech Reqs.</a>
            </li>
            <li>
              <a href="bystate">GrowFlow by State</a>
            </li>
          </ul> */}
        </div>
        <div className="ten wide tablet five wide computer column">
          <a
            href="https://app.getgrowflow.com/Account/FreeTrial"
            className="ui big positive right labeled icon trial button GAds-trial"
          >
            <i className="right arrow icon"></i>Start free trial
          </a>
          <button
            onClick={toggleOpen}
            className="ui big positive right labeled icon demo button GAds-demo"
          >
            <i className="right arrow icon"></i>Book A Demo
          </button>
          <Logo />
          <div className="ui grid">
            <div className="five column row">
              <div className="column">
                <a
                  className="ui circular facebook icon button"
                  href="https://www.facebook.com/getgrowflow/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="facebook icon"></i>
                </a>
              </div>
              <div className="column">
                <a
                  className="ui circular instagram icon button"
                  href="https://www.instagram.com/growflowinc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="instagram icon"></i>
                </a>
              </div>
              <div className="column">
                <a
                  className="ui circular twitter icon button"
                  href="https://twitter.com/getGrowFlow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="twitter icon"></i>
                </a>
              </div>
              <div className="column">
                <a
                  className="ui circular youtube icon button"
                  href="https://www.youtube.com/channel/UCKqJH5QXqxexB2n7ds1_NKA/videos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="youtube icon"></i>
                </a>
              </div>
              <div className="column">
                <a
                  className="ui circular linkedin icon button"
                  href="https://www.linkedin.com/company/growflow-corp/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="linkedin icon"></i>
                </a>
              </div>
            </div>
          </div>
          <a className="contact-info" href="tel:2064944689">
            (206) 494-4689
          </a>
          <p className="contact-info">
            500 Mercer St. STE C202-139A,
            <br />
            Seattle, WA 98109
          </p>
        </div>
      </div>
      <div className="ui stackable grid container legal">
        <div className="ui row">
          <div className="right floated right aligned eight wide column">
            <a href="https://getgrowflow.com/privacy-policy/">Privacy Policy</a> ||{' '}
            <a href="https://getgrowflow.com/eula/">EULA</a>
          </div>
          <div className="right floated left aligned eight wide column">
            <p>
              Copyright © by <strong>GrowFlow</strong> 2019. All right reserved.
            </p>
            <VersionNumber />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default IntroFooter;
